export function dynamicSort(property: string) {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function getHumanValueStorage(valueInBytes: number): string {
  return valueInBytes.toString().length < 12
    ? `${(valueInBytes / 1073741824).toFixed(2)} GB`
    : `${(valueInBytes / 1099511627776).toFixed(2)} TB`
}
