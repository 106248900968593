import {useFormik} from 'formik'
import {useRef, useState} from 'react'
import Swal from 'sweetalert2'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'
import {login} from '../core/_requests'

export default function ModalOtp({
  onClose,
  userName,
  password,
}: {
  onClose: () => void
  userName: string
  password: string
}) {
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState<any>(['', '', '', '', '', ''])
  function onPaste(event: {clipboardData: {getData: (arg0: string) => any}}) {
    const pasted = event.clipboardData.getData('text/plain')
    setOtp(pasted.split('').slice(0, otp.length))
  }
  const otpInputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]

  const handleAlert = () => {
    Swal.fire({
      icon: 'error',
      text: 'Invalid OTP',
      confirmButtonColor: '#009EF7',
    })
  }

  const initialValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        const response: Response = await fetch(`${BASE_URL}/usage_stats/validate_otp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          body: JSON.stringify({
            username: userName,
            otp: otp.join(''),
          }),
        })

        if (Boolean(process.env.REACT_APP_TESTING) === true || response.status === 200) {
          const {access: jwtToken, refresh: jwtRefreshToken} = await login(userName, password)

          const [_, payload, ...rest] = jwtToken.split('.')
          const decoded = JSON.parse(atob(payload))
          setStatus(null)
          saveAuth({api_token: jwtToken, refreshToken: jwtRefreshToken})

          fetch(`${BASE_URL}/resellers/?name=${decoded.name}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${jwtToken}` ?? '',
            },
          })
            .then((response) => {
              if (!response.ok) throw new Error('Unauthorized')

              return response.json()
            })
            .then((data) => {
              const reseller_id = data[0]?.url?.split('/')[4]
              const user = {
                id: decoded.user_id,
                name: decoded.name,
                groups: decoded.groups.toString(),
                resellerId: reseller_id,
              }

              localStorage.setItem('user', JSON.stringify(user))

              setCurrentUser(user)

              if (user.groups === 'reseller_user') {
                fetch(`${BASE_URL}/usage_stats/reseller_user_data/${user.name}`, {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${jwtToken}` ?? '',
                  },
                })
                  .then((response) => {
                    if (!response.ok) throw new Error('Unauthorized')

                    return response.json()
                  })
                  .then((data) => {
                    const user = {
                      id: decoded.user_id,
                      name: decoded.name,
                      groups: decoded.groups.toString(),
                      resellerId: data.reseller.id,
                      resellerUserId: data.reseller_user_id,
                      role: data.type,
                    }

                    localStorage.setItem('user', JSON.stringify(user))

                    setCurrentUser(user)
                  })
              }
            })
        } else {
          handleAlert()
        }
      } catch (error) {
        handleAlert()
        console.error(error)
      }
    },
  })

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedOtp: string[] = [...otp]
    updatedOtp[index] = event.target.value
    setOtp(updatedOtp)

    if (event.target.value === '' && index > 0) {
      otpInputRefs[index - 1].current?.focus()
    } else if (index < otpInputRefs.length - 1 && event.target.value !== '') {
      otpInputRefs[index + 1].current?.focus()
    }
  }

  return (
    <div>
      <form
        noValidate
        onSubmit={(e) => {
          formik.handleSubmit(e)
        }}
      >
        <div className='py-10 px-10  card w-50 mx-auto'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div
                className='btn btn-icon btn-sm ms-2 d-flex justify-content-end w-100'
                data-bs-dismiss='modal'
                onClick={onClose}
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
              <div className='modal-header pb-6  m-auto d-flex flex-column'>
                <h3 className='modal-title py-3'>Verify</h3>
                <p>Your OTP was sent to you via email</p>
              </div>

              <div className='modal-body pb-3'>
                <div className='otp-input-container d-flex'>
                  {otp.map((digit: number, index: number) => (
                    <input
                      type='text'
                      className=' form-control otp-input w-25 mx-2 text-center p-4'
                      key={index}
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      maxLength={1}
                      onPaste={onPaste}
                      ref={otpInputRefs[index]}
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
              </div>
              <div className='py-6 m-auto'>
                <button className='btn btn-primary' type='submit'>
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
