import {Navigate, Routes, Route, Outlet, Link, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {PaymentMethod} from './PaymentMethod'
import {Invoices} from './Invoices'
import {Statements} from './Statements'
import {UserAccount} from './UserAccount'
import {ResellerProfileDetails} from './ResellerProfileDetails'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/reseller-profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ResellerProfile: React.FC = () => {
  const location = useLocation()

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const role = user.role

  console.log('role', role)

  return (
    <Routes>
      <Route
        element={
          <>
            {role === 'Accounting' ? (
              <div className='d-flex flex-column flex-xl-row  justify-content-around '>
                <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
                  <ResellerProfileDetails />
                </div>
                <div className='flex-lg-row-fluid ms-lg-15 '>
                  <div>
                    <div className='d-flex overflow-auto h-45px mb-5'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/crafted/pages/profile/overview' && 'active')
                            }
                            to='/crafted/pages/profile/overview'
                          >
                            Overview
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                            }
                            to='/crafted/pages/profile/campaigns'
                          >
                            Statements
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </div>
            ) : role === 'Engineer' ? (
              <div className='d-flex flex-column flex-xl-row  justify-content-around '>
                <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
                  <ResellerProfileDetails />
                </div>
                <div className='flex-lg-row-fluid ms-lg-15 '>
                  <div>
                    <div className='d-flex overflow-auto h-45px mb-5'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/crafted/pages/profile/projects' && 'active')
                            }
                            to='/crafted/pages/profile/projects'
                          >
                            User Accounts
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </div>
            ) : role === 'Read-Only' ? (
              <div className='d-flex flex-column flex-xl-row  justify-content-around '>
                <div className='flex-column flex-lg-row-auto w-100 w-xl-500px mb-10'>
                  <ResellerProfileDetails />
                </div>

                <div className='flex-lg-row-fluid ms-lg-15 d-xl-block  d-none border justify-content-center card h-550px'>
                  <div>
                    <div className='d-flex justify-content-center overflow-auto  mb-5'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                          <div className={`nav-link text-active-primary me-6 `}>Overview</div>
                        </li>

                        <li className='nav-item'>
                          <div className={`nav-link text-active-primary me-6 `}>User Accounts</div>
                        </li>
                        <li className='nav-item'>
                          <div className={`nav-link text-active-primary me-6 `}>Statements</div>
                        </li>
                      </ul>
                    </div>

                    <div className=' d-flex justify-content-center align-items-center  h-300px'>
                      <div className='text-center mt-10'>
                      <i className="fa-solid fa-lock " style={{fontSize:"90px"}}></i>
                      <div className='fw-bold fs-2 mt-5'>No Access! </div>
                      <div> You don't have access to this area.  </div>
                      </div>
                    </div>

                
                  </div>

                  <div></div>
                </div>
              </div>
            ) : (
              <div className='d-flex flex-column flex-xl-row  justify-content-around '>
                <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
                  <ResellerProfileDetails />
                </div>
                <div className='flex-lg-row-fluid ms-lg-15 '>
                  <div>
                    <div className='d-flex overflow-auto h-35px mb-5'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/crafted/pages/profile/overview' && 'active')
                            }
                            to='/crafted/pages/profile/overview'
                          >
                            Overview
                          </Link>
                        </li>

                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/crafted/pages/profile/projects' && 'active')
                            }
                            to='/crafted/pages/profile/projects'
                          >
                            User Accounts
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                            }
                            to='/crafted/pages/profile/campaigns'
                          >
                            Statements
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </div>
            )}
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <PaymentMethod className='mb-5 mb-xl-8' />
              <Invoices className='mb-5 mb-xl-8' />
            </>
          }
        />
        <Route
          path='projects'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>User Accounts</PageTitle>

              <UserAccount className='mb-5 mb-xl-8' />
            </>
          }
        />
        <Route
          path='campaigns'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Statements</PageTitle>
              <Statements className='mb-5 mb-xl-8' />
            </>
          }
        />
        {role === 'Engineer' ? (
          <Route index element={<Navigate to='/crafted/pages/profile/projects' />} />
        ) : (
          <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
        )}
      </Route>
    </Routes>
  )
}

export default ResellerProfile
