import {useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {ResellersStat} from '../../../types/reseller'
import {getHumanValueStorage} from '../../../utility/utils'

type props = {
  clients: ResellersStat[]
  onlyUsed?: boolean
  includeStorage?: boolean
  managedWorkload?: boolean
}

export default function ExportClientAsCSV({
  clients,
  onlyUsed = false,
  includeStorage = true,
  managedWorkload = true,
}: props) {
  const [exporting, setExporting] = useState<Boolean>()
  const columns1 = [
    'Name',
    ...(includeStorage ? ['Storage Usage'] : []),
    managedWorkload ? 'Managed Workload' : 'Managed Workload',
    'B&R Enterprise',
    'B&R Enterprise Plus',
    'Veeam Server Agent',
    'Veeam Workstation Agent',
    'Veeam Cloud Connect (VM)',
    'Veeam Cloud Connect (Workstation)',
    'Veeam Cloud Connect (Server)',
    'Veeam Backup for M365',
  ]
  const columns2 = [
    'Name',
    'Storage Usage',
    'Managed Workload',
    'B&R Enterprise',
    'B&R Enterprise Plus',
    'Veeam Server Agent',
    'Veeam Workstation Agent',
    'Veeam Cloud Connect (VM)',
    'Veeam Cloud Connect (Workstation)',
    'Veeam Cloud Connect (Server)',
    'Veeam Backup for M365',
  ]

  const exportClients = async () => {
    const myExportedArr: any[] = []
    // const csvRows = [columns];
    clients?.forEach((item, i) => {
      myExportedArr.push(
        onlyUsed
          ? [
              item.name.replaceAll(',', ' '),
              ...(includeStorage ? [getHumanValueStorage(item.storage_usage ?? 0)] : []),
              // item.managed_workload,

              managedWorkload
                ? item.managed_workload
                : item.br_enterprise + item.br_enterprise_plus + item.veeam_server_agent,

              item.br_enterprise,
              item.br_enterprise_plus,
              item.veeam_server_agent,
              item.veeam_workstation_agent,
              item.veeam_cloud_connect_vm,
              item.veeam_cloud_connect_workstation,
              item.veeam_cloud_connect_server,
              item.veeam_backup_for_m365,
            ]
          : [
              item.name.replaceAll(',', ' '),
              getHumanValueStorage(item.storage_usage ?? 0),
              item.is_managed
                ? item.br_enterprise +
                  item.br_enterprise_new +
                  item.br_enterprise_plus +
                  item.br_enterprise_plus_new +
                  item.veeam_server_agent +
                  item.veeam_server_agent_new
                : 0,

              item.br_enterprise + item.br_enterprise_new,
              item.br_enterprise_plus + item.br_enterprise_plus_new,
              item.veeam_server_agent + item.veeam_server_agent_new,
              item.veeam_workstation_agent + item.veeam_workstation_agent_new,
              item.veeam_cloud_connect_vm + item.veeam_cloud_connect_vm_new,
              item.veeam_cloud_connect_workstation + item.veeam_cloud_connect_workstation_new,
              item.veeam_cloud_connect_server + item.veeam_cloud_connect_server_new,
              item.veeam_backup_for_m365 + item.veeam_backup_for_m365_new,
            ]
      )
    })
    const columns = [onlyUsed ? columns1 : columns2, ...myExportedArr]
    setExporting(true)
    try {
      const csvContent = 'data:text/csv;charset=utf-8,' + columns.map((e) => e.join(',')).join('\n')
      const url = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `clients-${Date.now()}.csv`)
      document.body.appendChild(link)
      link.click()
      setExporting(false)
      link.remove()
    } catch (error) {
      console.log('error', error)
      setExporting(false)
    }
  }
  return (
    <div>
      {exporting ? (
        <Spinner />
      ) : (
        <Button
          className='btn btn-primary btn-sm'
          type='button'
          onClick={() => exportClients()}
          disabled={clients.length > 0 ? false : true}
        >
          Export CSV
        </Button>
      )}
    </div>
  )
}
