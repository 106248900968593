interface CostValues {
  reseller: {
    name: string
    id: string
  }
  br_enterprise: number
  br_enterprise_plus: number
  storage_usage: number
  veeam_backup_for_m365: number
  veeam_cloud_connect_server: number
  veeam_cloud_connect_vm: number
  veeam_cloud_connect_workstation: number
  veeam_server_agent: number
  veeam_workstation_agent: number
}

function CostToChangeTable({
  values,
  editableRows,
  handleInputChange,
  modes,
  handleSaveClick,
  handleEditClick,
  handleDeleteClick,
}: {
  values: CostValues[]
  editableRows: number[]
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, index: number, field: string) => void
  modes: string[]
  handleSaveClick: (index: number) => void
  handleEditClick: (index: number) => void
  handleDeleteClick: (index: number) => void
}) {
  return (
    <tbody>
      {values.map((item, i) => (
        <tr>
          <td className='ps-5 text-primary fw-bold'>{item.reseller.name}</td>
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.storage_usage}
              type='number'
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'storage_usage');
                }
              }}
            ></input>
          </td>
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.br_enterprise}
              type='number'
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'br_enterprise');
                }
              }}
            ></input>
          </td>
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.br_enterprise_plus}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'br_enterprise_plus');
                }
              }}
              type='number'
            ></input>
          </td>{' '}
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.veeam_server_agent}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'veeam_server_agent');
                }
              }}
              type='number'
            ></input>
          </td>{' '}
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.veeam_workstation_agent}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'veeam_workstation_agent');
                }
              }}
              type='number'
            ></input>
          </td>{' '}
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.veeam_cloud_connect_vm}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'veeam_cloud_connect_vm');
                }
              }}
              type='number'
            ></input>
          </td>{' '}
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.veeam_cloud_connect_workstation}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'veeam_cloud_connect_workstation');
                }
              }}
              type='number'
            ></input>
          </td>{' '}
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.veeam_cloud_connect_server}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'veeam_cloud_connect_server');
                }
              }}
              type='number'
            ></input>
          </td>{' '}
          <td>
            <input
              className={` form-control w-100px ${editableRows.includes(i) ? 'editable' : ''}`}
              disabled={!editableRows.includes(i)}
              defaultValue={item.veeam_backup_for_m365}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e, i, 'veeam_backup_for_m365');
                }
              }}
              // onChange={(e) => handleInputChange(e, i, 'veeam_backup_for_m365')}
              type='number'
            />
          </td>
          <td>
            {modes[i] === 'edit' ? (
              <button className='btn btn-primary btn-sm' onClick={() => handleSaveClick(i)}>
                Save
              </button>
            ) : (
              <button className='btn btn-primary btn-sm' onClick={() => handleEditClick(i)}>
                Edit
              </button>
            )}
          </td>
          <td>
            <button className='btn btn-danger btn-sm' onClick={() => handleDeleteClick(i)}>
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default CostToChangeTable
