import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {BASE_URL} from '../../../../utility/constants'
import Swal from 'sweetalert2'

const initialValues = {
  email: '',
  newPassword: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm New Password is required'),
})

export function ResetPassword() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const id = searchParams.get('id')
  const token = searchParams.get('token')

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Password reset successfully!',
      confirmButtonColor: '#009EF7',
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        const response = await fetch(`${BASE_URL}/usage_stats/reset_password`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            new_password: values.newPassword,
            id: id,
            token: token,
          }),
        })
        if (response.status === 200) {
          setHasErrors(false)
          setLoading(false)
          setStatus('Password reset successful!!')
          handleAlert()
          navigate('/auth')
        } else {
          setLoading(false)
          setHasErrors(true)
          setStatus('An error occurred while resetting the password.')
        }
      } catch (error) {
        setLoading(false)
        setHasErrors(true)
        setStatus('An error occurred while resetting the password.')
        console.error(error)
      }
    },
  })

  return (
    <div className='container'>
      <div className='row d-flex align-items-center  ' style={{height: '70vh'}}>
        <div className='col ms-5'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_reset_password_form'
            onSubmit={(e) => {
              formik.handleSubmit(e)
            }}
          >
            <div className=' mb-10'>
              <h1 className=' fw-bolder mb-3 text-primary'> Reset Password</h1>

              <div className='text-gray-500 fw-semibold fs-6'></div>
            </div>

            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='alert alert-success ' role='alert'>
                Password reset successful!
              </div>
            )}

            <div className='fv-row mb-8 position-relative'>
              <label className='form-label required fw-bolder text-gray-900 fs-6'>
                New Password
              </label>
              <input
                type={showNewPassword ? 'text' : 'password'}
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                name='newPassword'
                className={clsx('form-control bg-transparent')}
              />
              {showNewPassword ? (
                <i
                  className='bi bi-eye-slash position-absolute '
                  style={{fontSize: '26px', right: '20px', top: '35px', cursor: 'pointer'}}
                  onClick={() => setShowNewPassword(false)}
                ></i>
              ) : (
                <i
                  className='bi bi-eye position-absolute   '
                  style={{fontSize: '26px', right: '20px', top: '35px', cursor: 'pointer'}}
                  onClick={() => setShowNewPassword(true)}
                ></i>
              )}
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8 position-relative'>
              <label className='form-label required fw-bolder text-gray-900 fs-6'>
                Confirm New Password
              </label>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('confirmPassword')}
                name='confirmPassword'
                className={clsx('form-control bg-transparent')}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container mt-5'>
                  <span role='alert' className='text-danger '>
                    {formik.errors.confirmPassword}
                  </span>
                </div>
              )}
              {showConfirmPassword ? (
                <i
                  className='bi bi-eye-slash position-absolute'
                  style={{fontSize: '26px', right: '20px', top: '35px', cursor: 'pointer'}}
                  onClick={() => setShowConfirmPassword(false)}
                ></i>
              ) : (
                <i
                  className='bi bi-eye position-absolute'
                  style={{fontSize: '26px', right: '20px', top: '35px', cursor: 'pointer'}}
                  onClick={() => setShowConfirmPassword(true)}
                ></i>
              )}
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-wrap  pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-primary me-4 w-100'
              >
                <span className='indicator-label'>Reset Password</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
