const IMAGE_BASE_PATH = '/media/cards/'
export const cardImages: {
  [key: string]: string
  visa: string
  mastercard: string
  paypal: string
  maestro: string
  american_express: string
  amazon: string
} = {
  amazon: IMAGE_BASE_PATH + 'amazon.png',
  american_express: IMAGE_BASE_PATH + 'american_express.png',
  maestro: IMAGE_BASE_PATH + 'maestro.png',
  mastercard: IMAGE_BASE_PATH + 'mastercard.png',
  paypal: IMAGE_BASE_PATH + 'paypal.png',
  visa: IMAGE_BASE_PATH + 'visa.png',
}
