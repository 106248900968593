import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../accounts/components/settings/SettingsModel'
import {useFormik} from 'formik'
import ModalProfile from './ModalProfile'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'

const ResellerProfileDetails: React.FC = () => {
  const {auth} = useAuth()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [userData, setUserData] = useState<IProfileDetails>(initialValues)

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId
  const role = user.role
  const group = user.groups
  console.log('group', group)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProfileData = () => {
    fetch(`${BASE_URL}/usage_stats/reseller_profile/${resellerUid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((fetchedData) => {
        setData(fetchedData)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getProfileData()
  }, [])

  const formik = useFormik<IProfileDetails>({
    initialValues,
    onSubmit: (values) => {},
  })

  const handleCloseModal = () => {

    getProfileData()
   if( user.groups === "reseller_user"){

     getResellerUserData()
   }
  }

  const getResellerUserData = () => {
    fetch(`${BASE_URL}/usage_stats/reseller_user_data/${user.name}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')

        return response.json()
      })
      .then((data) => {
        setUserData(data)
      })
  }

  useEffect(() => {
    if (user.groups === 'reseller_user') {
      getResellerUserData()
    }
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body pt-9 pb-0'>
          <div className='  '>
            <div className='d-flex flex-center flex-column mb-5'>
              <div className='symbol symbol-100px symbol-circle border mb-7'>
                {group === 'reseller_user' ? (
                  <img
                    src={
                      userData.profile_image !== null
                        ? `${BASE_URL}/static/profile_images/${userData.profile_image}`
                        : toAbsoluteUrl('/media/avatars/blank.png')
                    }
                    alt=''
                    className='rounded-circle'
                  />
                ) : (
                  <img
                    src={
                      data.reseller.profile_image
                        ? `${BASE_URL}/static/profile_images/${data.reseller.profile_image}`
                        : toAbsoluteUrl('/media/avatars/blank.png')
                    }
                    alt=''
                    className='rounded-circle'
                  />
                )}
              </div>
            </div>

            <div>
              <div className=' align-items-center text-center mb-2 '>
                <div className='text-gray-800  fs-2 fw-bolder me-1 '>
                  {group === 'reseller_user' ? user.name.charAt(0).toUpperCase()+ user.name.slice(1) : data.reseller.name.charAt(0).toUpperCase()+  data.reseller.name.slice(1)}
                </div>
                {Boolean(process.env.REACT_APP_TESTING) === true ? (
                  <div>
                    ({group}
                    {role})
                  </div>
                ) : null}
              </div>

              <div>
                <div>
                  <div className='card mb-5 mb-xl-10'>
                    <div
                      className='card-toolbar mx-auto mt-5'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Click to add a user'
                    >
                      <div
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_profile'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        Edit
                      </div>
                    </div>
                    <ModalProfile handleCloseModal={handleCloseModal} />

                    <div id='kt_account_profile_details' className='collapse show'>
                      <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        {role === 'Read-Only' ? (
                          <div className='card-body mt-5   p-9'>
                            <div className='mb-5 '>
                              <span className='fw-bold fs-6'>Account ID :</span>{' '}
                              {group === 'reseller_user' ? user.resellerUserId : data.reseller.id}
                            </div>

                            <div className='mb-5 '>
                              <span className='  fw-bold fs-6'>Account Owner Contact : </span>
                              {group === 'reseller_user'
                                ? userData.account_owner_contact
                                : data.reseller.account_owner_contact}
                            </div>

                            <div className='mb-5 '>
                              <span className=' fw-bold fs-6'>Billing Contact : </span>
                              {group === 'reseller_user'
                                ? userData.billing_contact
                                : data.reseller.billing_contact}
                            </div>

                            <div className=' mb-5 '>
                              <span className=' fw-bold fs-6'>Support Contact : </span>
                              {group === 'reseller_user'
                                ? userData.support_contact
                                : data.reseller.support_contact}
                            </div>
                          </div>
                        ) : (
                          <div className='card-body  p-9'>
                            <div className='row '>
                              <label className={'col-lg-12 col-form-label  fw-bold fs-6'}>
                                Account ID
                              </label>
                              <p className=' fs-6 text-gray-600'>
                              {group === 'reseller_user' ? `${user.resellerUserId.substring(0, 18)}...` : `${data.reseller.id.substring(0, 18)}...`}

                               
                              </p>
                            </div>

                            <div className='row '>
                              <label className='col-lg-12 col-form-label  fw-bold fs-6'>
                                Account Owner Contact
                              </label>
                              <p className=' fs-6 text-gray-600'>
                              {group === 'reseller_user'
                                ? userData.account_owner_contact
                                : data.reseller.account_owner_contact}
                              </p>
                            </div>

                            <div className='row '>
                              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                <span>Billing Contact</span>
                              </label>
                              <p className=' fs-6 text-gray-600'>
                              {group === 'reseller_user'
                                ? userData.billing_contact
                                : data.reseller.billing_contact}
                                
                                </p>
                            </div>

                            <div className='row d-flex '>
                              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                <span>Support Contact</span>
                              </label>
                              <p className=' fs-6 text-gray-600'>
                              {group === 'reseller_user'
                                ? userData.support_contact
                                : data.reseller.support_contact}
                              </p>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ResellerProfileDetails}
