import clsx from 'clsx'
import {useLayout} from '../../core'
// import {Footer} from './Footer'

const FooterWrapper = () => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <div className={clsx('app-container m-auto  ', config.app.footer.containerClass)}>
          {/* <Footer /> */}
          <p> © Copyright 2023 Storio. All rights reserved.</p>
        </div>
      ) : (
        <div>
          <p>© Copyright 2023 Storio. All rights reserved.</p>
        </div>
        // <Footer />
      )}
    </div>
  )
}

export {FooterWrapper}
