import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import MonthlyLicensingGraphs from '../pages/resellers/monthly-report/graphs-wrapper'
import {LicensingBillingReport} from '../pages/resellers/monthly-report/licensing-report'
import IndividualResellerReport from '../pages/resellers/monthly-report/individual-reseller'
import Dashboard from '../pages/dashboard/Dashboard'
import {useAuth} from '../modules/auth'
import DefaultCostPerUnit from '../pages/payment-settings/default-cost'
import ProtectedData from '../pages/resellers/protected-data-report/ProtectedData'

const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
const ResellerListWrapper = lazy(() => import('../pages/resellers/daily-report/reseller-list'))
const ResellerStatsWrapper = lazy(() => import('../pages/resellers/daily-report/reseller-stats'))
const ManageManagedClients = lazy(() => import('../pages/management/ManageManagedClients'))

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  return (
    <Routes>
      {/******   all routes  ********/}
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<Dashboard />} />
        {/* <Route path='dashboard/:resellerUid' element={<Dashboard />} /> */}
        <Route path='resellers' element={<ResellerListWrapper />} />
        <Route path='resellers/:resellerUid' element={<ResellerStatsWrapper />} />
        <Route
          path='resellers/:resellerUid/organizations/:organizationUid'
          element={<ResellerStatsWrapper />}
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />

        {/******   monthly report routes  ********/}
        <Route path='/monthly-licensing-graphs' element={<MonthlyLicensingGraphs />} />
        <Route path='/licensing-billing-report' element={<LicensingBillingReport />} />

        <Route path='/manage-managed-clients' element={<ManageManagedClients />} />
        <Route path='/manage-managed-clients/:resellerUid' element={<ManageManagedClients />} />


        <Route path='/protected-data-report' element={<ProtectedData />} />




        <Route
          path='/individual-monthly-licensing/:month/:resellerUid'
          element={<IndividualResellerReport />}
        />

        {/******   payment settings routes  ********/}
        <Route path='default-cost' element={<DefaultCostPerUnit/>} />


        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
