import {useEffect, useState} from 'react'

import {Elements} from '@stripe/react-stripe-js'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'
import {loadStripe} from '@stripe/stripe-js'
import {CheckoutForm} from './CheckoutForm'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)

function StripeAddNewCard(props: any) {
  const [clientSecret, setClientSecret] = useState<string>()
  const {auth} = useAuth()

  const appearance = {
    variables: {
      colorText: 'gray',
    },
  }

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const userID = user.resellerId

  useEffect(() => {
    const getClientSecret = () => {
      fetch(`${BASE_URL}/usage_stats/create_setup_intent/${userID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          setClientSecret(data?.clientSecret)
        })

        .catch((error) => {
          console.error(error)
        })
    }
    getClientSecret()
  }, [auth?.api_token, userID])

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{clientSecret, appearance}}>
          <CheckoutForm handleGetPaymentDetail={props.handleGetPaymentDetail}/>
        </Elements>
      )}
    </>
  )
}

export default StripeAddNewCard
