/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import DetailsInvoice from './DetailsInvoice'
import ModalShow from './Modal'
import ModalRemove from './ModalRemove' 
import RemoveInvoice from './RemoveInvoice'

type InvoiceItem = {
  id: string
  created: number
  status: string
  total: number
}

const InvoiceDropdown: FC<{id: string | number; handleClose: () => void; details: InvoiceItem; toRemove: boolean; fetchInvoices:()=>void }> = ({
  id,
  handleClose,
  details,
  toRemove,
  fetchInvoices,



}) => {
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalRemove, setShowModalRemove] = useState(false)

  const onCloseDetail = () => {
    setShowModalEdit(false)
  }
  const onCloseRemove = () => {
    setShowModalRemove(false)
  }

  return (
    <>
      <div className='dropdown ' >
        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
          <li>
            <div className='dropdown-item'>
              <div
                onClick={() => setShowModalEdit(true)}
                className=' px-3 text-dark'
                style={{cursor: 'pointer'}}
              >
                <i className='bi bi-eye me-4 text-dark'></i> Detail
              </div>
            </div>
          </li>
          <li >
            <div 
            className={` dropdown-item ${!toRemove ? 'bg-light-secondary' : ''} `}
            >
              
              {' '}
                {' '}
                <button
                  onClick={() => setShowModalRemove(true)}
                  className={` text-danger   `}
                  style={{cursor: 'pointer', border: 'none', background: 'none'}}
                  disabled={!toRemove}
                >
                  <i className='bi bi-trash3 text-danger ms-1 me-3'></i> Remove
                </button>
            
            </div>
          </li>
        </ul>
      </div>

      <ModalShow
        content={<DetailsInvoice details={details} onClose={onCloseDetail} id={id} />}
        onClose={onCloseDetail}
        showModal={showModalEdit}
      />
      <ModalShow
        content={<RemoveInvoice onClose={onCloseRemove}  id={id} fetchInvoices={fetchInvoices} />}
        onClose={onCloseRemove}
        showModal={showModalRemove}
       
      />
    </>
  )
}

export {InvoiceDropdown}
