/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import EditPayment from './EditPayment'
import ModalShow from './Modal'

import ModalRemovePaymentCard from './ModalRemovePaymentCard'

type DropdownPaymentProps = {
  id: string | number
  handleClose: () => void
  defaultCard: string
  isPrimary: boolean
}

const DropdownPayment: FC<DropdownPaymentProps> = ({id, handleClose, defaultCard, isPrimary}) => {
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalRemove, setShowModalRemove] = useState(false)

  const onCloseEdit = () => {
    handleClose()
    setShowModalEdit(false)
  }
  const onCloseRemove = () => {
    handleClose()
    setShowModalRemove(false)
  }

  return (
    <>
      <div className='dropdown'>
        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
          <li>
            <div className='dropdown-item'>
              <div
                onClick={() => setShowModalEdit(true)}
                className=' ps-6 text-dark'
                style={{cursor: 'pointer'}}
              >
                <i className='bi bi-pencil text-dark me-4'></i> Edit
              </div>
            </div>
          </li>
          <li >
            <div 
            // className='dropdown-item bg-light-secondary '
            className={` dropdown-item ${isPrimary ? 'bg-light-secondary' : ''} `}
            >
              {' '}
              <div className='dropdown-item'>
                {' '}
                <button
                  onClick={() => setShowModalRemove(true)}
                  className={` text-danger   `}
                  style={{cursor: 'pointer', border: 'none', background: 'none'}}
                  disabled={isPrimary}
                >
                  <i className='bi bi-trash3 text-danger me-4'></i> Remove
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <ModalShow
        content={<EditPayment onClose={onCloseEdit} id={id.toString()} isPrimary={isPrimary} />}
        onClose={onCloseEdit}
        showModal={showModalEdit}
      />
      <ModalShow
        content={<ModalRemovePaymentCard onClose={onCloseRemove} id={id} />}
        onClose={onCloseRemove}
        showModal={showModalRemove}
      />
    </>
  )
}

export {DropdownPayment}
