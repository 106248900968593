import {SearchReseller} from './SearchReseller'

interface CostValues {
  br_enterprise: number
  br_enterprise_plus: number
  storage_usage: number
  veeam_backup_for_m365: number
  veeam_cloud_connect_server: number
  veeam_cloud_connect_vm: number
  veeam_cloud_connect_workstation: number
  veeam_server_agent: number
  veeam_workstation_agent: number
}

function ModalAddCostPerUnitTable({
  formRef,
  dispatchReset = false,
  setDispatchReset,
  setSelectedResellerId,
  isCustomizeSelected,
  data,
  setCustomData,
}: {
  formRef: React.MutableRefObject<HTMLFormElement | null>
  dispatchReset: boolean
  setDispatchReset: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedResellerId: React.Dispatch<React.SetStateAction<string | null | undefined>>
  isCustomizeSelected: boolean
  data: CostValues | undefined
  setCustomData: React.Dispatch<React.SetStateAction<CostValues>>
}) {
  return (
    <div className='modal-body'>
      <form ref={formRef} id={'addCost'}>
        <table className='table gs-7 gy-7 gx-7'>
          <tbody>
            <div className='row d-flex align-items-center'>
              <div className='col fw-bold'> Reseller: </div>
              <div className='col'>
                <SearchReseller
                  reset={dispatchReset}
                  onReset={(cb: Function) => {
                    cb()
                    setDispatchReset(false)
                  }}
                  setSelectedResellerId={setSelectedResellerId}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col fw-bold'> Storage Usage: </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.storage_usage}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                 
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      storage_usage: +e.target.value,
                    }))
                  }
              
                />{' '}
              </div>
            </div>
            <div className='row'>
              <div className='col fw-bold'> B & R enterprise: </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.br_enterprise}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      br_enterprise: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
            <div className='row'>
              <div className='col fw-bold'> BR enterprise plus: </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.br_enterprise_plus}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      br_enterprise_plus: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
            <div className='row'>
              <div className='col fw-bold'>veeam server agent: </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.veeam_server_agent}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      veeam_server_agent: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
            <div className='row'>
              <div className='col fw-bold'> veeam workstation agent: </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.veeam_workstation_agent}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      veeam_workstation_agent: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
            <div className='row'>
              <div className='col fw-bold'> veeam cloud connect (VM): </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.veeam_cloud_connect_vm}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      veeam_cloud_connect_vm: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
            <div className='row'>
              <div className='col fw-bold'>veeam cloud connect (workstation): </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.veeam_cloud_connect_workstation}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      veeam_cloud_connect_workstation: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>
            <div className='row'>
              <div className='col fw-bold'> veeam cloud connect (server): </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.veeam_cloud_connect_server}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      veeam_cloud_connect_server: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
            <div className='row'>
              <div className='col fw-bold'>veeam backup for m365: </div>
              <div className='col'>
                {' '}
                <input
                  disabled={!isCustomizeSelected}
                  type='number'
                  className='form-control h-10px w-200px'
                  placeholder='0'
                  defaultValue={isCustomizeSelected ? '' : data?.veeam_backup_for_m365}
                  onKeyPress={(e) => {
                    if (e.key === '.' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setCustomData((prevData) => ({
                      ...prevData,
                      veeam_backup_for_m365: +e.target.value,
                    }))
                  }
                />{' '}
              </div>
            </div>{' '}
          </tbody>
        </table>
      </form>
    </div>
  )
}

export default ModalAddCostPerUnitTable
