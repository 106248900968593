import React, {useEffect, useState} from 'react'
import { Dropdown} from 'react-bootstrap'
import {ResellersStat} from '../../../types/reseller'
import {useStats} from '../../../stores/use-stats'
import {useAuth} from '../../modules/auth'
import {dynamicSort} from '../../../utility/utils'

interface SearchResellerProps {
  setSelectedResellerId: (id: string | null) => void
  onReset?: (cb: Function) => void
  reset?: boolean
}

const SearchReseller: React.FC<SearchResellerProps> = ({
  setSelectedResellerId,
  reset = undefined,
  onReset,
}) => {
  const [value, setValue] = useState<string>('')
  const [dropdown, setDropdown] = useState<ResellersStat[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [mouseOver, setMouseOver] = useState<boolean>(false)
  const resellersStats = useStats((state) => state.resellersStats)
  const {currentUser} = useAuth()



  useEffect(() => {
    if(reset===undefined){
      return
    }
    if (reset === true && onReset) {
      onReset(() => {
        setValue('');
      });
    }
  }, [reset, onReset]);

  useEffect(() => {
    const resellerData = resellersStats?.sort(dynamicSort('name'))
    if (currentUser?.groups === 'reseller') {
      const data = resellerData.filter((item) => item.name === currentUser.name)
      setDropdown(data)
    } else {
      setDropdown(
        resellerData.filter((item) => item.name?.toLowerCase().includes(value.toLowerCase()))
      )
    }
  }, [currentUser?.groups, currentUser?.name, resellersStats, value])

  /****** Handle search Dropdown *******/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValue(e.target.value)
    setSelectedResellerId(null)
  }

  const clearSelection = () => {
    setValue('')
    setSelectedResellerId(null)
  }

  return (
    <div>
      {' '}
      <div className=''>
        <input
          type='search'
          className='form-control h-10px'
          aria-label='Default select example'
          placeholder='Resellers...'
          value={value}
          onFocus={() => setShow(true)}
          onBlur={() => !mouseOver && setShow(false)}
          onClick={clearSelection}
          onChange={(e) => {
            handleSearchChange(e)
          }}
          style={{
            width: '200px',
          }}
        />

        <div className='overflow-hidden'>
          <Dropdown.Menu
            style={{
              maxHeight: 300,
              width: '200px',
              overflowY: 'auto',
              marginTop: '10px',
            }}
            onMouseEnter={() => {
              setMouseOver(true)
            }}
            onMouseLeave={() => {
              setMouseOver(false)
            }}
            show={show}
          >
            {dropdown?.map((item: ResellersStat) => (
              <Dropdown.Item
                onClick={() => {
                  setShow(false)
                  setValue(item.name)
                  setSelectedResellerId(item.id)
                }}
                key={item.id}
                onTouchEnd={() => {
                  setShow(false)
                  setValue(item.name)
                  setSelectedResellerId(item.id)
                }}
              >
                {item.name.slice(0, 20)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </div>
      </div>
    </div>
  )
}

export {SearchReseller}
