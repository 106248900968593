import React from 'react'
import {MonthGraph} from '../../../components/monthly-report-graphs/ month-graph'
import {AllLicensesMonth} from '../../../components/monthly-report-graphs/all-licenses-month'

export default function MonthlyLicensingGraphs() {
  return (
    <div>
      <MonthGraph className='' />
      <AllLicensesMonth className='mt-4' />
    </div>
  )
}
