/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import ExportClientAsCSV from '../../../components/clients/export-client'
import LicensingFilter from '../../../components/filter/licensing-filter'
import {Spinner} from 'react-bootstrap'
import useLicensesReport from '../../../../apis/use-licenses-report'

import $ from 'jquery'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-dt/js/dataTables.dataTables.min'
import 'datatables.net-buttons/js/dataTables.buttons.min'
import 'datatables.net-buttons/js/buttons.html5.min'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../../modules/auth'
import UpdatePendingInvoice from '../../../modules/profile/reseller-profile/UpdatePendingInvoice'

type InvoiceItem = {
  id: string
  created: number
  status: string
  total: number
  account_name: string
}

export function LicensingBillingReport() {
  const {auth} = useAuth()
  const now = new Date()
  const {loading, licensingReport, getSummary} = useLicensesReport()
  const [amounts, setAmounts] = useState<any>([])
  const [invoice, setInvoice] = useState<InvoiceItem[] | undefined>(undefined)
  const [loadingAmounts, setLoadingAmounts] = useState(false)
  const [selectedReseller, setSelectedReseller] = useState<string | null>(null)

  const date =
    now.getMonth() < 10
      ? `0${now.getMonth() + 1}-${now.getFullYear()}`
      : `${now.getMonth() + 1}-${now.getFullYear()}`

  const [selectedValue, setSelectedValue] = useState(date)

  const [filterDate, setFilterDate] = useState(() => {
    const newMonth = now.getMonth() === 0 ? '12' : now.getMonth()
    const newYear = now.getMonth() === 0 ? String(Number(now.getFullYear()) - 1) : now.getFullYear()

    return now.getMonth() < 10
      ? now.getMonth() === 0
        ? `${newMonth}-${newYear}`
        : `0${newMonth}-${newYear}`
      : `${newMonth}-${newYear}`
  })

  const tableRef = useRef(null)

  useEffect(() => {
    if (tableRef?.current && licensingReport.length !== 0) {
      const dataTable = $(tableRef?.current)?.DataTable()

      return () => {
        dataTable?.destroy()
      }
    }
  }, [licensingReport])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [month, year] = filterDate.split('-')
        if (Number(month) === 0) {
          await getSummary(`12`, `${Number(year) - 1}`)
        } else if (Number(month) < 10) {
          await getSummary(`0${Number(month)}`, year)
        } else {
          await getSummary(`${Number(month)}`, year)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [filterDate, getSummary])

  const paymentAmount = useCallback(async () => {
    setLoadingAmounts(true)
    try {
      const response = await fetch(`${BASE_URL}/usage_stats/resellers_detail`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
      })

      if (!response.ok) {
        throw new Error('Unauthorized')
      }

      const data = await response.json()
      setAmounts(data.resellers)
      setLoadingAmounts(false)
    } catch (error) {
      console.error(error)
      setLoadingAmounts(false)
    }
  }, [auth?.api_token])
  useEffect(() => {
    paymentAmount()
  }, [paymentAmount])

  if (loading) {
    return (
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    )
  }

  const handlePendingInvoice = async (resellerId: string) => {
    setSelectedReseller(resellerId)

    setInvoice([])
    try {
      const response = await fetch(`${BASE_URL}/usage_stats/pending_invoices/${resellerId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
      })

      if (!response.ok) {
        throw new Error('Unauthorized')
      }

      const data = await response.json()
      setInvoice(data.invoices)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={`card pt-5`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-row '>
          <span className='card-label fw-bold fs-3 mb-1 pt-1'>
            Monthly Billing Report for {filterDate}
          </span>
        </h3>
        <div className='d-flex my-2 gap-2'>
          <div className='mr-4 d-flex align-items-center position-relative'>
            <ExportClientAsCSV clients={licensingReport} onlyUsed={true} managedWorkload={true} />
          </div>

          <LicensingFilter
            value={selectedValue}
            defaultValue={filterDate}
            onChanged={(date) => {
              const [month, year] = date.split('-').map(Number)
              let newMonth, newYear

              if (month === 1) {
                newMonth = 12
                newYear = year - 1
              } else {
                newMonth = month - 1 < 10 ? `0${month - 1}` : `${month - 1}`
                newYear = year
              }

              const newDate = `${newMonth}-${newYear}`
              setFilterDate(newDate)
              setSelectedValue(date)
            }}
          />
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          {loading ? (
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          ) : licensingReport?.length !== 0 ? (
            <table
              ref={tableRef}
              id='resellerDataTable'
              className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
            >
              <thead>
                <tr className='fw-bold text-muted text-capitalize'>
                  <th className='min-w-150px'>Reseller Name</th>
                  <th className='min-w-70px'>Managed Workloads</th>
                  <th className='min-w-70px'>B & R enterprise</th>
                  <th className='min-w-70px'>BR enterprise plus</th>
                  <th className='min-w-70px'>veeam server agent</th>
                  <th className='min-w-70px'>veeam workstation agent</th>
                  <th className='min-w-70px'>veeam cloud connect (VM)</th>
                  <th className='min-w-70px'>veeam cloud connect (workstation)</th>
                  <th className='min-w-70px'>veeam cloud connect (server)</th>
                  <th className='min-w-70px'>veeam backup for m365</th>
                  <th className='min-w-70px'>veeam backup</th>
                  <th className='min-w-70px'>Replication Standard License Usage</th>
                  <th className='min-w-70px'>Amount (in $)</th>
                </tr>
              </thead>
              {licensingReport.length !== 0 ? (
                <tbody>
                  {licensingReport?.map((reseller: any) => {
                    return (
                      <tr key={reseller.reseller_id}>
                        <td>
                          <Link
                            style={{
                              paddingLeft: 0,
                            }}
                            to={`/individual-monthly-licensing/${filterDate ? filterDate : date}/${
                              reseller.reseller_id
                            }`}
                          >
                            {reseller.name}
                          </Link>
                        </td>
                        <td>{reseller.managed_workload}</td>
                        <td>{reseller.br_enterprise} </td>
                        <td>{reseller.br_enterprise_plus} </td>
                        <td>{reseller.veeam_server_agent} </td>
                        <td>{reseller.veeam_workstation_agent}</td>
                        <td>{reseller.veeam_cloud_connect_vm}</td>
                        <td>{reseller.veeam_cloud_connect_workstation}</td>
                        <td>{reseller.veeam_cloud_connect_server}</td>
                        <td>{reseller.veeam_backup_for_m365} </td>
                        <td>0</td>
                        <td>0</td>
                        <td className='d-flex  justify-content-between align-items-center gap-5'>
                          <div>
                            {loadingAmounts ? (
                              <div>Loading..</div>
                            ) : (
                              Number(
                                amounts.find(
                                  (item: {id: string}) => item.id === reseller.reseller_id
                                )?.billing_amount * 0.01 || 0.0
                              ).toFixed(2)
                            )}
                          </div>

                          <div>
                          
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              onClick={() => handlePendingInvoice(reseller.reseller_id)}
                              data-bs-toggle='modal'
                              data-bs-target='#pendingInvoiceAdmin'
                            >
                              Edit
                            </button>
                            <UpdatePendingInvoice
                              invoice={invoice}
                              paymentAmount={paymentAmount}
                              resellerIds={selectedReseller ? selectedReseller : ''}
                              handlePendingInvoice={handlePendingInvoice}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td className='text-center'>No data</td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <div className='pb-5'>No Data found...</div>
          )}
        </div>
      </div>
    </div>
  )
}
