import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'
import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import { requestPassword } from '../../auth/core/_requests'

const modalAddUserSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  fName: Yup.string().min(3, 'minimum 3 characters required').required('Name is required'),
  role: Yup.string().required('A radio option is required'),
})

export default function ModalAddNewUser({handleClose}: {handleClose: () => void}) {
  const {auth} = useAuth()
  const [imageBase64, setImageBase64] = useState<string | null>(null)
  const [profileImage, setProfileImage] = useState<File | null>(null)

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'User added successfully!',
      confirmButtonColor: '#009EF7',
    })
  }
  const handleAlertError = () => {
    Swal.fire({
      icon: 'error',
      text: 'Error detected!',
    })
  }


  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: modalAddUserSchema,
    onSubmit: (values) => {


      const formData = new FormData()

      formData.append('email', formik.values.email ?? '')
      formData.append('name', formik.values.fName ?? '')
      formData.append('type', formik.values.role ?? '')
      if (profileImage) {
        formData.append('profile_image', profileImage)
      }else{
        formData.append('profile_image', "")

      }

     






      fetch(`${BASE_URL}/usage_stats/reseller_users/${resellerUid}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.message || 'Unauthorized');
            });
          }
          setImageBase64(null)

          fetch(`${BASE_URL}/usage_stats/reseller_user_setup`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth?.api_token}` ?? '',
            },
            body: JSON.stringify({email: formik.values.email}),
          })
            .then((response) => {
              if (!response.ok) throw new Error('Unauthorized')
              return response.json()
            })
            
            .catch((e) => {
              console.log(e.message)
            })



          handleAlert()
          handleClose()
        
       
   
          
          return response.json()

        })

        .catch((error) => {
          console.error(error)
          if (error.message) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message,
            });
          } else {
            handleAlertError(); 
          }
          formik.resetForm()
         
  
        })
        .finally(() => {
       
          formik.resetForm();
          formik.setFieldValue('email', '');
          formik.setFieldValue('role', '');
          setImageBase64(null);
        });
    },
  })

  return (
    <>
      <div className='modal fade' id='kt_modal_profile_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>Create new user</h3>

              <div
                className='btn btn-icon btn-sm btn-active-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className='modal-body '>
              <div>
                <div id='kt_account_profile_details' className='collapse show'>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    className='form'
                    encType='multipart/form-data'
                  >
                    <div>
                      <div className='col-lg-8'>
                        <p className=' fs-5'>Avatar</p>
                        <div
                          className='image-input image-input-outline d-flex align-items-center gap-5'
                          data-kt-image-input='true'
                        >
                          <div className='symbol symbol-100px symbol-circle mb-7'>
                            <img
                              src={imageBase64 ?? toAbsoluteUrl('/media/avatars/blank.png')}
                              alt=''
                            />
                          </div>
                          <div className='text-primary position-relative' style={{cursor: 'pointer'}}>
                            Change
                            <input
                              name='profile_image'
                              type='file'
                              accept='.png, .jpeg, .jpg'
                              style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                fontSize: '0',

                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                              }}
                              onChange={(e) => {
                                const selectedFile = e.target?.files ? e.target?.files[0] : null
                                setProfileImage(selectedFile)

                                if (selectedFile) {
                                  const reader = new FileReader()
                                  reader.onload = (event) => {
                                    if (event.target) {
                                      const base64String = event.target.result as string
                                      setImageBase64(base64String)
                                    }
                                  }
                                  reader.readAsDataURL(selectedFile)
                                }
                              }}
                            />
                          </div>
                          <div className='text-danger ' style={{cursor: 'pointer'}}>
                            Remove
                          </div>
                        </div>
                        <p className='text-muted pt-3 fs-5'>
                          Allowed file types: .png, .jpeg, .jpg
                        </p>
                      </div>
                      <div className='row mb-2'>
                        <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                          Full name
                        </label>

                        <div className='col-lg-12'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                placeholder='User name'
                                {...formik.getFieldProps('fName')}
                              />
                              {formik.touched.fName && formik.errors.fName && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.fName}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-2'>
                        <label className='col-lg-12 col-form-label  required fw-bold fs-6'>
                          Email address
                        </label>

                        <div className='col-lg-12 fv-row'>
                          <input
                            type='email'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='email@example.com'
                            {...formik.getFieldProps('email')}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.email}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <label className='col-lg-12 col-form-label  required fw-bold fs-6 mb-3'>
                        Role
                      </label>

                      <div className='form-check'></div>

                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Administrator'
                            checked={formik.values.role === "Administrator" ? true :false}
                          />
                        </div>

                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Administrator</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              All Permissions to their Reseller Account
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Engineer'
                            checked={formik.values.role === "Engineer" ? true :false}
                          />
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Engineer</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Access to Alerts,Storage Usage,any IT related Task, can Edit
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Accounting'
                            checked={formik.values.role === "Accounting" ? true :false}
                          />
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Accounting</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Invoicing only & possibly Company Details edits, Payment Methods
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Read-Only'
                            checked={formik.values.role === "Read-Only" ? true :false}
                          />
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6' >Read-Only</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Access to Alerts,Storage Usage but can't edit
                            </span>
                          </div>
                        </div>
                      </div>

                      {formik.submitCount > 0 && !formik.values.role && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>A role is required</div>
                        </div>
                      )}
                    </div>

                    <div className=' d-flex justify-content-end  px-9 gap-5'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Close
                      </button>
                      <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                        Create user
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
