import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import MonthlyLicensingGraphs from '../pages/resellers/monthly-report/graphs-wrapper'
import {LicensingBillingReport} from '../pages/resellers/monthly-report/licensing-report'
import IndividualResellerReport from '../pages/resellers/monthly-report/individual-reseller'
import Dashboard from '../pages/dashboard/Dashboard'
import {useAuth} from '../modules/auth'
import ResellerProfile from '../modules/profile/reseller-profile/ResellerProfile'

import {CheckYourMail} from '../modules/auth/components/CheckYourMail'
import {ResetPassword} from '../modules/auth/components/ResetPassword'
import {CreateAccount} from '../modules/auth/components/CreateAccount'
import StartTrial from '../modules/auth/components/StartTrial'
import ResellerForgotPassword from '../modules/auth/components/ResellerForgotPassword'

const ResellerStatsWrapper = lazy(() => import('../pages/resellers/daily-report/reseller-stats'))
const ManageManagedClients = lazy(() => import('../pages/management/ManageManagedClients'))

const UserRoutes = () => {
  return (
    <Routes>
      {/******   all routes  ********/}
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        <Route path='dashboard' element={<Dashboard />} />
 
        <Route path='resellers/:resellerUid' element={<ResellerStatsWrapper />} />
        
        <Route
          path='resellers/:resellerUid/organizations/:organizationUid'
          element={<ResellerStatsWrapper />}
        />

        {/******   monthly report routes  ********/}

        <Route path='/licensing-billing-report' element={<LicensingBillingReport />} />
        {/* <Route path='/monthly-licensing-graphs' element={<MonthlyLicensingGraphs />} /> */}

        <Route path='/manage-managed-clients/:resellerUid' element={<ManageManagedClients />} />

        <Route
          path='/individual-monthly-licensing/:month/:resellerUid'
          element={<IndividualResellerReport />}
        />

        {/******   to login forgot password and mail  ********/}

        <Route path='/components/ResellerForgotPassword' element={<ResellerForgotPassword />} />

        <Route path='/components/CheckYourMail' element={<CheckYourMail />} />

        <Route path='/components/ResetPassword?:id?:token' element={<ResetPassword />} />

        <Route path='/components/CreateAccount' element={<CreateAccount />} />

        <Route path='/components/StartTrial' element={<StartTrial />} />

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ResellerProfile />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserRoutes}
