export interface IProfileDetails {
  [x: string]: any
 
  avatar?: string
  fName?: string
  lName?: string
  company?: string
  email?:string
  role?: string
  contactPhone?: string
  companySite?: string
  country?: string
  language?: string
  timeZone?: string
  currency?: string
 
  communications?: {
    email: boolean
    phone: boolean
  }
  allowMarketing?: boolean
  reseller: {
    profile_image: string;
    name: string;
    id: string;
    account_owner_contact: number;
    billing_contact: number;
    support_contact: number;
  },

}

export interface IPaymentMethod {
  nameOnCard : string
  cardCvv : string
  cardExpiryYear : string
  cardExpiryMonth : string
  cardNumber : string
  // setAsPrimary: boolean

}

export const paymentMethod: IPaymentMethod = {
  nameOnCard : "",
  cardCvv : "",
  cardExpiryYear : "",
  cardExpiryMonth : "",
  cardNumber : "",
  // setAsPrimary : false
}

export interface IUserAccountItem {
  id?: string;
  // user: {
    name?: string;
    email?: string;
  // };
  role?: string;
}





export interface ModalProfileProps {
  updateData: (fieldsToUpdate: Partial<IProfileDetails>) => void;
}


export interface IResellerProfileDetails {
  email: string ;
  profile_image: string;
    name: string;
    id: string;
    account_owner_contact: string | number; 
  billing_contact: string | number;       
  support_contact: string | number;      
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: '',
  lName: '',
  role:'',
  company: '',
  contactPhone: '',
  companySite: '',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
  reseller: {
    profile_image: '',
    name: '',
    id: '',
    account_owner_contact: 0,
    billing_contact: 0,
    support_contact: 0,
  },
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
