/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  className: string
}
interface SampleData {
  date: string
  orderId: string
  amount: number
}

const sampleData: SampleData[] = [
  {date: '2023/02/25', orderId: 'aa123', amount: 1.6},
  {date: '2023/03/26', orderId: 'ss234', amount: -2.6},
  {date: '2023/04/27', orderId: 'cc645', amount: 4.6},
]
interface SampleData {
  date: string
  orderId: string
  amount: number
  [key: string]: string | number
}
const Statements: React.FC<Props> = ({className}) => {
  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [sortOrderDate, setSortOrderDate] = useState<'asc' | 'desc'>('asc')
  const [sortOrderOrderId, setSortOrderOrderId] = useState<'asc' | 'desc'>('asc')
  const [sortOrderAmount, setSortOrderAmount] = useState<'asc' | 'desc'>('asc')

  const handleCheckboxChange = (value: string) => {
    const updatedSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value]

    setSelectedItems(updatedSelectedItems)
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    setSelectedItems(selectAll ? [] : sampleData.map((item) => item.orderId))
  }

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  const [sortConfig, setSortConfig] = useState<{field: string; order: 'asc' | 'desc'}>({
    field: 'date',
    order: 'asc',
  })

  const handleSort = (field: 'date' | 'orderId' | 'amount') => {
    setSortConfig((prev) => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc',
    }))
  }

  const sortedData = [...sampleData].sort((a, b) => {
    if (sortConfig.field === 'date') {
      return sortConfig.order === 'asc'
        ? new Date(a.date).getTime() - new Date(b.date).getTime()
        : new Date(b.date).getTime() - new Date(a.date).getTime()
    }

    if (sortConfig.field === 'orderId') {
      return sortConfig.order === 'asc'
        ? a.orderId.localeCompare(b.orderId)
        : b.orderId.localeCompare(a.orderId)
    }

    return sortConfig.order === 'asc'
      ? (a[sortConfig.field] as number) - (b[sortConfig.field] as number)
      : (b[sortConfig.field] as number) - (a[sortConfig.field] as number)
  })

  return (
    <div className='card '>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title'>Statement</h3>
        <div className=' float-right   mt-5'>
          <div className='d-flex align-items-center position-relative me-4 '>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-3 position-absolute ms-3 text-primary '
            />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9 py-3 '
              placeholder='Search Order ID'
              value={searchInput}
              onChange={handleSearchInputChange}
              
            />
          </div>
        </div>
      </div>
      <div className='card-body'>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_7' role='tabpanel'>
            <div className='table-responsive'>
              <table id='resellerDataTable' className='table gs-7 gy-7 gx-7'>
                <thead>
                  <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200 align-middle'>
                    <th className='d-flex gap-5' >
                      {' '}
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{cursor:"pointer"}}
                        />
                      </div>
                      <div>
                        Date {' '}
                        <i
                         style={{cursor: 'pointer'}}
                          onClick={() => handleSort('date')}
                          className={`bi bi-arrow-down-up ${
                            sortOrderDate === 'asc' ? 'up' : 'down'
                          }`}
                        ></i>
                      </div>
                    </th>
                    <th >
                      Order ID{' '}
                      <i
                       style={{cursor: 'pointer'}}
                        onClick={() => handleSort('orderId')}
                        className={`bi bi-arrow-down-up ${
                          sortOrderOrderId === 'asc' ? 'up' : 'down'
                        }`}
                      ></i>
                    </th>
                    <th >
                      Amount{' '}
                      <i
                       style={{cursor: 'pointer'}}
                        onClick={() => handleSort('amount')}
                        className={`bi bi-arrow-down-up ${
                          sortOrderAmount === 'asc' ? 'up' : 'down'
                        }`}
                      ></i>
                    </th>
                    <th className='text-center'>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData
                    .filter((item) =>
                      item.orderId.toLowerCase().includes(searchInput.toLowerCase())
                    )
                    .map((item) => (
                      <tr className='align-middle' key={item.orderId}>
                        <td className='d-flex gap-5'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid '>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value={item.orderId}
                              checked={selectedItems.includes(item.orderId)}
                              onChange={() => handleCheckboxChange(item.orderId)}
                              style={{cursor:"pointer"}}
                            />
                          </div>
                          {item.date}
                        </td>
                        <td>{item.orderId}</td>

                        <td
                          className={`fw-bold ${item.amount >= 0 ? 'text-success' : 'text-danger'}`}
                        >
                          {item.amount >= 0
                            ? `+$${item.amount.toFixed(2)}`
                            : `-$${Math.abs(item.amount).toFixed(2)}`}
                        </td>

                        <td className='text-center'>
                          <button type='button' className='btn btn-sm btn-icon btn-color-primary '>
                            <i className='bi bi-download pe-3'></i>
                            Download
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className='d-flex justify-content-end flex-stack flex-wrap pt-10'>
                <div className=' flex-stack  flex-wrap pt-10'>
                  <ul className='pagination'>
                    <li className='page-item previous' style={{cursor:"pointer"}}>
                      <a href='#' className='page-link'>
                        <i className='fa-solid fa-angles-left'></i>
                      </a>
                    </li>

                    <li className=''>
                      <a href='#' className='page-link'>
                        1
                      </a>
                    </li>

                    <li className='page-item next' style={{cursor:"pointer"}}>
                      <a href='#' className='page-link'>
                        <i className='fa-solid fa-angles-right'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Statements}
