import Swal from 'sweetalert2'
import {BASE_URL} from '../../../../utility/constants'
import {KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'

export default function ModalRemovePaymentCard({
  id,
  onClose,
}: {
  id: string | number
  onClose: () => void
}) {
  const {auth} = useAuth()

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Deleted successfully!',
      confirmButtonColor: '#009EF7',
    })
  }

  const handleRemoveCard = () => {
    fetch(`${BASE_URL}/usage_stats/remove_card/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        handleAlert()
        onClose()
        return response.json()
      })

      .catch((error) => {
        console.error(error)
      })
  }
  return (
    <div>
      <div className='py-8 px-10 card '>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header pb-10'>
              <h3 className='modal-title '>Confirmation</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                data-bs-dismiss='modal'
                onClick={onClose}
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className='modal-body text-center p-5'>
              <KTSVG
                path='/media/icons/duotune/general/gen027.svg'
                className='svg-icon-danger svg-icon-3hx '
              />
              <h3 className='mt-5'>Are you sure to remove it?</h3>
            </div>

            <div className='modal-footer pt-6 '>
              <button type='button' className='btn btn-light me-2' onClick={onClose}>
                Close
              </button>
              <button type='button' className='btn btn-danger' onClick={handleRemoveCard}>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
