import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {BASE_URL} from '../../../utility/constants'
import Swal from 'sweetalert2'
import {ModalAddCostPerUnit} from './ModalAddCostPerUnit'
import CostToChangeTable from './CostToChangeTable'

interface CostValues {
  reseller: {
    name: string
    id: string
  }
  br_enterprise: number
  br_enterprise_plus: number
  storage_usage: number
  veeam_backup_for_m365: number
  veeam_cloud_connect_server: number
  veeam_cloud_connect_vm: number
  veeam_cloud_connect_workstation: number
  veeam_server_agent: number
  veeam_workstation_agent: number
}

export default function CostToChange() {
  const tableRef = useRef(null)
  const {auth} = useAuth()
  const [values, setValues] = useState<CostValues[]>([])
  const [editableRows, setEditableRows] = useState<number[]>([])
  const [modes, setModes] = useState<string[]>([])

  const getcostToChange = () => {
    fetch(`${BASE_URL}/usage_stats/get_costs_for_reseller`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((fetchedData) => {
        console.log('getcostToChange', fetchedData)
        setValues(fetchedData.data)
      })

      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getcostToChange()
  }, [])

  const handleEditClick = (index: number) => {
    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(index)
        ? prevEditableRows.filter((rowIndex) => rowIndex !== index)
        : [...prevEditableRows, index]
    )
    setModes((prevModes) => {
      const updatedModes = [...prevModes]
      updatedModes[index] = 'edit'
      return updatedModes
    })
  }

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Editted successfully!',
      confirmButtonColor: '#009EF7',
    })
  }
  const handleAlertDelete = () => {
    Swal.fire({
      icon: 'success',
      text: 'Deleted successfully!',
      confirmButtonColor: '#009EF7',
    })
  }

  const handleSaveClick = (index: number) => {
    const editedRow = values[index]
    fetch(`${BASE_URL}/usage_stats/reseller_cost/${editedRow.reseller.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
      body: JSON.stringify(editedRow),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to update')
        getcostToChange()
        handleAlert()

        return response.json()
      })

      .catch((error) => {
        console.error(error)
      })
    setEditableRows((prevEditableRows) => prevEditableRows.filter((rowIndex) => rowIndex !== index))
    setModes((prevModes) => {
      const updatedModes = [...prevModes]
      updatedModes[index] = 'view'
      return updatedModes
    })
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => {
    const newValue = e.target.value

    setValues((prevValues) => {
      const updatedValues = [...prevValues]
      updatedValues[index] = {
        ...updatedValues[index],
        [field]: newValue,
      }
      return updatedValues
    })
  }

  const handleDeleteClick = (index: number) => {
    const editedRow = values[index]
    fetch(`${BASE_URL}/usage_stats/reseller_cost/${editedRow.reseller.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to update')
        getcostToChange()
        handleAlertDelete()
        return response.json()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className='card table-responsive ' style={{maxHeight: '600px'}}>
      <div className='card-header border-0 pt-5 d-flex'>
        <h3 className='card-title align-items-start flex-row '>
          <span className='card-label fw-bold fs-3 mb-1 pt-1'>Cost Per Unit For Resellers</span>
        </h3>
        <div>
          <button
            className='btn btn-primary btn-sm '
            data-bs-toggle='modal'
            data-bs-target='#modalAddCost'
          >
            Add
          </button>
          <ModalAddCostPerUnit getcostToChange={getcostToChange} />
        </div>
      </div>
      <table
        ref={tableRef}
        id='resellerDataTable'
        className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
      >
        <thead>
          <tr className='fw-bold text-muted text-capitalize'>
            <th className='min-w-100px ps-5'>Reseller</th>

            <th className='min-w-100px ps-5'>Storage Usage</th>
            <th className='min-w-100px'>B & R enterprise</th>
            <th className='min-w-100px'>BR enterprise plus</th>
            <th className='min-w-100px'>veeam server agent</th>
            <th className='min-w-100px'>veeam workstation agent</th>
            <th className='min-w-100px'>veeam cloud connect (VM)</th>
            <th className='min-w-100px'>veeam cloud connect (workstation)</th>
            <th className='min-w-100px'>veeam cloud connect (server)</th>
            <th className='min-w-100px'>veeam backup for m365</th>
            <th className='min-w-100px'> Edit</th>
            <th className='min-w-100px'> Delete</th>
          </tr>
        </thead>
        <CostToChangeTable
          values={values}
          editableRows={editableRows}
          handleInputChange={handleInputChange}
          modes={modes}
          handleSaveClick={handleSaveClick}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      </table>
    </div>
  )
}
