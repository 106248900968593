/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {monthsMap} from '../filter/dates'
import useCallApi from '../../../apis/api'

type Props = {
  className: string
}
const d = new Date()
const default_date = {
  month: ('0' + d.getMonth()).slice(-2).toString(),
  year: (d.getFullYear() - 1).toString(),
}

const MonthGraph: React.FC<Props> = ({className}) => {
  const [graphData, setGraphData] = useState([])
  const [plotableData, setPlotableData] = useState([])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {getData, loading} = useCallApi()

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, plotableData))
    if (chart) {
      chart.render()
    }

    return chart
  }
  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, plotableData])

  ////////*****  Get Graph Data *****////////

  useEffect(() => {
    getData('total_points/').then((data) => {
      setGraphData(data)
    })
  }, [])

  useEffect(() => {
    let sortedData = []
    graphData
      ?.sort((a, b) => {
        const aDate = a.month.split('-')
        const bDate = b.month.split('-')
        if (Number(aDate[1]) === Number(bDate[1])) {
          return Number(aDate[0]) - Number(bDate[0])
        } else {
          return Number(bDate[0]) - Number(aDate[0])
        }
      })
      ?.forEach((v) => {
        const date = v.month.split('-')
        const index = sortedData.findIndex(
          (d) => d.x === monthsMap[date[0]] + " '" + date[1].slice(-2)
        )
        if (index === -1) {
          sortedData.push({
            x: monthsMap[date[0]] + " '" + date[1].slice(-2),
            y: v.total_points,
          })
        } else {
          sortedData[index].y += v.total_points
        }
      })

    setPlotableData(sortedData)
  }, [graphData])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Last 12 Months Points Graph</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {MonthGraph}

function getChartOptions(height: number, plotableData): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: 'Total Points',
        data: plotableData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [labelColor],
      },
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      type: 'category',
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
