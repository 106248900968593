import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react'
import {IResellerProfileDetails} from '../../accounts/components/settings/SettingsModel'
import {useFormik} from 'formik'
import {useAuth} from '../../auth'
import {BASE_URL} from '../../../../utility/constants'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import RemoveProfileImage from './RemoveProfileImage'
import ModalProfileForm from './ModalProfileForm'

export default function ModalProfile({
  handleCloseModal,
  resellerIds,
}: {
  handleCloseModal: () => void
  resellerIds?: string
}) {
  const [imageBase64, setImageBase64] = useState<string | null>(
    toAbsoluteUrl('/media/avatars/blank.png')
  )
  const [profileImage, setProfileImage] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
  }

  const resetFileInput = () => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.value = null
    }
  }

  const {auth} = useAuth()
  const [data, setData] = useState({
    profile_image: '',
    name: '',
    email: '',
    id: '',
    account_owner_contact: '',
    billing_contact: '',
    support_contact: '',
  })
  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null

  const [formInitialValues, setFormInitialValues] = useState<IResellerProfileDetails>({
    profile_image: '',
    name: '',
    email: '',
    id: '',
    account_owner_contact: '',
    billing_contact: '',
    support_contact: '',
  })

  const getProfileData = useCallback(
    (resellerUid: string) => {
      setLoading(true)

      let apiUrl = `${BASE_URL}/usage_stats/reseller_profile/${resellerUid}`
      if (user.groups === 'reseller_user') {
        apiUrl = `${BASE_URL}/usage_stats/reseller_user_data/${user.name}`
      }

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
      })
        .then((response) => {
          if (!response.ok) throw new Error('Unauthorized')
          return response.json()
        })
        .then((fetchedData) => {
          user.groups === 'reseller_user' ? setData(fetchedData) : setData(fetchedData.reseller)

          setImageBase64(`${BASE_URL}/static/profile_images/${data.profile_image}`)

          if (data.profile_image == null) {
            setImageBase64(toAbsoluteUrl('/media/avatars/blank.png'))
          }

          user.groups === 'reseller_user'
            ? setFormInitialValues({
                profile_image: fetchedData.profile_image || '',
                name: fetchedData.username || '',
                email: fetchedData.email || '',
                id: fetchedData.reseller_user_id || '',
                account_owner_contact: fetchedData.account_owner_contact || '',
                billing_contact: fetchedData.billing_contact || '',
                support_contact: fetchedData.support_contact || '',
              })
            : setFormInitialValues({
                profile_image: fetchedData.reseller.profile_image || '',
                name: fetchedData.reseller.name || '',
                email: fetchedData.reseller.email || '',
                id: fetchedData.reseller.id || '',
                account_owner_contact: fetchedData.reseller.account_owner_contact || '',
                billing_contact: fetchedData.reseller.billing_contact || '',
                support_contact: fetchedData.reseller.support_contact || '',
              })
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [auth?.api_token, data.profile_image, user.groups, user.name]
  )

  useEffect(() => {
    if (user.groups === 'reseller' || user.groups === 'reseller_user') {
      getProfileData(user.resellerId)
    } else {
      if (resellerIds === undefined) {
        console.log('resellerId undefined')
        return
      }

      if (resellerIds) {
        console.log('resellerIds MODAL', resellerIds)
        getProfileData(resellerIds)
      }
    }
  }, [getProfileData, resellerIds, user.groups, user.resellerId])

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const profileDetailsSchema = Yup.object().shape({
    support_contact: Yup.string()
      .nullable()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Enter minimum 10 numbers')
      .max(10, 'Enter maximum 10 numbers'),
    name: Yup.string()
      .required('Company name is required')
      .min(3, 'Minimum 3 characters are required'),
    account_owner_contact: Yup.string()
      .nullable()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Enter minimum 10 numbers')
      .max(10, 'Enter maximum 10 numbers'),
    billing_contact: Yup.string()

      .nullable()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Enter minimum 10 numbers')
      .max(10, 'Enter maximum 10 numbers'),
    email: Yup.string().nullable().email('Enter a valid email address'),
  })

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Profile edited!',
      confirmButtonColor: '#009EF7',
    })

    handleCloseModal()
  }

  const formik = useFormik<IResellerProfileDetails>({
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: profileDetailsSchema,

    onSubmit: (values) => {
      const formData = new FormData()

      formData.append('name', formik.values.name)
      formData.append('account_owner_contact', formik.values.account_owner_contact.toString())
      formData.append('billing_contact', formik.values.billing_contact.toString())
      formData.append('support_contact', formik.values.support_contact.toString())
      formData.append('email', formik.values.email)
      if (profileImage) {
        formData.append('profile_image', profileImage)
      } else {
        formData.append('profile_image', '')
      }

      let apiUrl = `${BASE_URL}/usage_stats/reseller_profile/${resellerIds}`

      if (user.groups === 'reseller') {
        apiUrl = `${BASE_URL}/usage_stats/reseller_profile/${user.resellerId}`
      }

      if (user.groups === 'reseller_user') {
        apiUrl = `${BASE_URL}/usage_stats/update_reseller_user/${user.resellerUserId}`
      }

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) throw new Error('Unauthorized')
          handleAlert()

          return response.json()
        })
        .then((fetchedData) => {
          setData(fetchedData)
        })
        .catch((error) => {
          console.error(error)
        })
    },
  })

  return (
    <>
      <div className='modal ' id='kt_modal_profile'>
        <div className='modal-dialog card'>
          <div className='modal-content '>
            <div className='modal-header'>
              <h3 className='modal-title'>Edit Profile </h3>
              <div
                className='btn btn-icon btn-sm btn-active-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className='modal-body  '>
              {loading ? (
                <div className='text-center'>
                  <div className='spinner-border text-primary' role='status'></div>
                </div>
              ) : (
                <ModalProfileForm
                  formik={formik}
                  imageBase64={imageBase64}
                  inputRef={inputRef}
                  handleFileChange={handleFileChange}
                  setProfileImage={setProfileImage}
                  setImageBase64={setImageBase64}
                  resetFileInput={resetFileInput}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <RemoveProfileImage />
    </>
  )
}
