import {useEffect, useRef, useState} from 'react'
import {ResellersStat} from '../../../../types/reseller'
import ExportClientAsCSV from '../../../components/clients/export-client'
import {getHumanValueStorage} from '../../../../utility/utils'
import {Button, Spinner} from 'react-bootstrap'
import {SendEmail} from './modals/SendEmail'
import $ from 'jquery'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-dt/js/dataTables.dataTables.min'
import 'datatables.net-buttons/js/dataTables.buttons.min'
import 'datatables.net-buttons/js/buttons.html5.min'

export default function MonthlyClients({clients = []}: {clients: ResellersStat[]}) {
  const [sendEmail, setSendEmail] = useState(false)
  let tableRef = useRef(null)
  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const role = user.role

  useEffect(() => {
    if (tableRef?.current && clients?.length !== 0) {
      const dataTable = $(tableRef?.current)?.DataTable()

      return () => {
        dataTable?.destroy()
      }
    }
  }, [clients?.length])

  return (
    <div className={`card mt-5`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-row'>
          <span className='card-label fw-bold fs-3 mb-1 pt-2'>Clients</span>
        </h3>
        <div className='d-flex my-2 gap-2 align-items-center'>
          <div className='mr-4 d-flex align-items-center position-relative'>
            <Button
              className=' mr-4 btn btn-primary btn-sm'
              type='button'
              onClick={() => setSendEmail(true)}
              disabled={role === 'Accounting' || role === 'Read-Only'}
            >
              Send Email
            </Button>
          </div>

          <div className='d-flex align-items-center position-relative'>
            <ExportClientAsCSV
              clients={clients}
              onlyUsed={true}
              includeStorage={true}
              managedWorkload={false}
            />
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {clients?.length !== 0 ? (
            <table
              ref={tableRef}
              id='resellerDataTable'
              className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
              style={{display: clients?.length !== 0 ? 'inherit' : 'none'}}
            >
              <thead>
                <tr className='fw-bold text-muted text-capitalize'>
                  <th className='min-w-80px'>Client Name</th>
                  <th className='min-w-80px'>storage usage (VM)</th>
                  <th className='min-w-120px'>Performance Tier</th>
                <th className='min-w-120px'>Capacity Tier</th>
                  <th className='min-w-80px'>Managed Workloads</th>
                  <th className='min-w-80px'>BR enterprise</th>
                  <th className='min-w-80px'>BR enterprise plus</th>
                  <th className='min-w-80px'>veeam server agent</th>
                  <th className='min-w-80px'>veeam workstation agent</th>
                  <th className='min-w-80px'>veeam cloud connect VM</th>
                  <th className='min-w-80px'>veeam cloud connect (workstation)</th>
                  <th className='min-w-80px'>veeam cloud connect (server)</th>
                  <th className='min-w-80px'>veeam backup for m365</th>
                  <th className='min-w-80px'>veeam backup</th>
                  <th className='min-w-80px'>Replication Standard License Usage</th>
                </tr>
              </thead>

              {clients?.length !== 0 ? (
                <>
                  <tbody>
                    {clients?.map((client, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{client?.name}</td>
                          <td>{getHumanValueStorage(client?.storage_usage)}</td>
                          <td>{getHumanValueStorage(client?.performance_tier ?? 0) }</td>
                    <td>{getHumanValueStorage(client?.capacity_tier ?? 0) }</td>
                   <td>
                            {client?.is_managed
                              ? client?.br_enterprise +
                                client?.br_enterprise_plus +
                                client?.veeam_server_agent
                              : 0}
                          </td>
                          <td>{client?.br_enterprise}</td>
                          <td>{client?.br_enterprise_plus}</td>
                          <td>{client?.veeam_server_agent}</td>
                          <td>{client?.veeam_workstation_agent}</td>
                          <td>{client?.veeam_cloud_connect_vm}</td>
                          <td>{client?.veeam_cloud_connect_workstation}</td>
                          <td>{client?.veeam_cloud_connect_server}</td>
                          <td>{client?.veeam_backup_for_m365}</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </>
              ) : (
                <div> No data found..</div>
              )}
            </table>
          ) : (
            <div className=''>
              <Spinner animation='border'></Spinner>
            </div>
          )}
        </div>
      </div>
      <SendEmail show={sendEmail} handleClose={() => setSendEmail(false)} />
    </div>
  )
}
