import React, {useEffect, useState} from 'react'
import {getHumanValueStorage} from '../../../../utility/utils'
import useCallApi from '../../../../apis/api'
import {ResellersStat} from '../../../../types/reseller'
import {Spinner} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import moment from 'moment'

export default function ProtectedData() {
  const [allServerData, setAllServerData] = useState<ResellersStat[]>([])
  const [selectedResellers, setSelectedResellers] = useState<string[]>([])
  const [showCriticalBackups, setShowCriticalBackups] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingManagedClients, setLoadingManagedClients] = useState(false)

  const handleCriticalBackupsClick = () => {
    setShowCriticalBackups(true)
  }
  const {getData} = useCallApi()

  useEffect(() => {
    getData(`usage_stats/protected_data/False`).then((data) => {
      setAllServerData(data?.data)
    })
  }, [getData])

  const handleShowAllDataClick = () => {
    setLoading(true)
    getData(`usage_stats/protected_data/False`).then((data) => {
      setAllServerData(data?.data)
      setLoading(false)
    })

    setSelectedResellers([])
    setShowCriticalBackups(false)
  }

  const handleManagedClient = () => {
    setLoadingManagedClients(true)
    getData(`usage_stats/protected_data/True`).then((data) => {
      setAllServerData(data?.data)
      setLoadingManagedClients(false)
    })
    setSelectedResellers([])
    setShowCriticalBackups(false)
  }

  const sortDate = (
    rowA: {latestRestorePointDate: moment.MomentInput},
    rowB: {latestRestorePointDate: moment.MomentInput}
  ) => {
    const dateA = moment(rowA.latestRestorePointDate, 'DD/MM/YYYY')
    const dateB = moment(rowB.latestRestorePointDate, 'DD/MM/YYYY')

    if (!dateA.isValid() && !dateB.isValid()) {
      return 0
    }

    if (!dateA.isValid()) {
      return -1
    }

    if (!dateB.isValid()) {
      return 1
    }

    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0
  }

  return (
    <div className={`card `}>
      <div className='card-header border-0 pt-5 d-flex align-items-center'>
        <h3 className='card-title align-items-start flex-row '>
          <span className='card-label fw-bold fs-1 mb-5 pt-1'>Protected Data Report</span>
        </h3>

        <div className='d-flex gap-4 '>
          <div className='btn-group'>
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='defaultDropdown'
              data-bs-toggle='dropdown'
              data-bs-auto-close='true'
              aria-expanded='false'
              onClick={handleShowAllDataClick}
            >
              Select Option
            </button>
            {loading ? (
              <ul className='dropdown-menu'>
                <li className='text-center'>
                  <Spinner animation='border' role='status' className=''></Spinner>
                </li>
              </ul>
            ) : (
              <ul className='dropdown-menu' aria-labelledby='defaultDropdown'>
                <li>
                  <button className='btn btn-sm  ps-7' onClick={handleManagedClient}>
                    Managed Clients
                  </button>
                </li>
                <li>
                  <button
                    className='btn btn-sm ps-7'
                    type='button'
                    onClick={handleCriticalBackupsClick}
                  >
                    Critical Backups
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className={`card mt-5`}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {loadingManagedClients ? (
              <div className='d-flex gap-5 align-items-center'>
                <Spinner animation='border' role='status' className=''></Spinner>
                <div>loading...</div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 card'>
                {allServerData.length !== 0 ? (
                  <tbody className='card-body'>
                    <DataTable
                      columns={[
                        {name: 'Reseller', selector: (row) => row.reseller_name, sortable: true},
                        {name: 'Client', selector: (row) => row.client_name, sortable: true},
                        {name: 'Server Name', selector: (row) => row.name, sortable: true},
                        {
                          name: 'Date last backed up',
                          selector: (row) => row.latestRestorePointDate,
                          sortable: true,

                          sortFunction: sortDate,
                        },
                      ]}
                      data={allServerData
                        .filter(
                          (item) =>
                            selectedResellers.length === 0 ||
                            selectedResellers.includes(item.reseller_name)
                        )
                        .flatMap((item) =>
                          item?.clients?.flatMap((client) => [
                            ...(client?.server_data || [])
                              .filter((server) => {
                                const lastBackupDate: number | undefined =
                                  server?.latestRestorePointDate
                                    ? new Date(server?.latestRestorePointDate).getTime()
                                    : undefined
                                const currentDate: number = new Date().getTime()
                                const daysSinceLastBackup: number | undefined = lastBackupDate
                                  ? Math.floor(
                                      (currentDate - lastBackupDate) / (1000 * 60 * 60 * 24)
                                    )
                                  : undefined

                                return (
                                  !showCriticalBackups ||
                                  (server &&
                                    server.latestRestorePointDate !== undefined &&
                                    (server.latestRestorePointDate instanceof Date ||
                                      server.latestRestorePointDate !== '') &&
                                    lastBackupDate !== undefined &&
                                    daysSinceLastBackup! >= 3)
                                )
                              })
                              .map((server) => ({
                                reseller_name: item?.reseller_name,
                                client_name: client?.client_name,
                                name: server?.name || '',

                                latestRestorePointDate: server?.latestRestorePointDate
                                  ? new Date(server?.latestRestorePointDate).toLocaleDateString()
                                  : '',
                              })),
                            ...(!showCriticalBackups && client?.server_data?.length === 0
                              ? [
                                  {
                                    reseller_name: item?.reseller_name,
                                    client_name: client?.client_name,
                                    name: '',

                                    latestRestorePointDate: '',
                                  },
                                ]
                              : []),
                          ])
                        )}
                      pagination
                    />
                  </tbody>
                ) : (
                  <tbody>
                    <div>

                    <Spinner animation='border' role='status' className='me-5'></Spinner>
                    Loading.....
                    </div>
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
