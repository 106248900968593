import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const response = await requestPassword(values.email)
        if (response.status === 200) {
          localStorage.setItem('email', values.email)
          setHasErrors(false)
          setLoading(false)
          setStatus('Password reset email sent. Please check your email.')
          navigate('/auth/check-email')
        } else {
          setHasErrors(true)
          setLoading(false)
          setStatus('Password reset request failed. Please try again.')
        }
      } catch (error) {
        setHasErrors(true)
        setLoading(false)
        setStatus('Password reset request failed. Please try again.')
      }
      setSubmitting(false)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className=' mb-10'>
        <h1 className=' fw-bolder mb-3 text-primary'>
          {' '}
          <Link to={'/'}>
            <i className='bi bi-arrow-left mt-5 fs-1 me-3 '></i>
          </Link>
          Forgot Password ?
        </h1>

        <div className='text-gray-500 fw-semibold fs-6'></div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label required fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder='email@example.com'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-transparent')}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap  pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4 w-100'>
          <span className='indicator-label'>Request reset link</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
