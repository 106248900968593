import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'
import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react'
import {IUserAccount, User} from '../../../../types/responseTypes/userAccount.type'
import Swal from 'sweetalert2'

const modalAddUserSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  username: Yup.string().min(3, 'minimum 3 characters required').required('Name is required'),
  role: Yup.string().required('A radio option is required'),
})

export default function ModalEditNewUser({
  id,
  onClose,
}: {
  id: number | string
  onClose: () => void
}) {
  const {auth} = useAuth()
  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId
  const [data, setData] = useState<User & {id: string; role: string}>({
    email: '',
    id: '',
    username: '',
    role: '',
  })

  const [imageBase64, setImageBase64] = useState<string | null>(null)
  const [profileImage, setProfileImage] = useState<File | null | string>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
  }

  const resetFileInput = () => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.value = null
    }
  }

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'User edited successfully!',
      confirmButtonColor: '#009EF7',
    })
  }

  const getProfileData = useCallback(() => {
    fetch(`${BASE_URL}/usage_stats/reseller_users/${resellerUid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')

        return response.json()
      })
      .then((fetchedData: {reseller: IUserAccount[]}) => {
        const currentReseller = fetchedData?.reseller.find((user) => user.id === id)

      

        setImageBase64(`${BASE_URL}/static/profile_images/${currentReseller?.profile_image}`)
        setProfileImage(`${BASE_URL}/static/profile_images/${currentReseller?.profile_image}`)

        if (currentReseller?.profile_image == null) {
          setImageBase64(toAbsoluteUrl('/media/avatars/blank.png'))
        }

        if (currentReseller !== undefined) {
          setData({
            ...currentReseller.user,

            id: currentReseller.id,
            role: currentReseller.type,
            username: currentReseller.username,
            email: currentReseller.email,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [auth?.api_token, id, resellerUid])
  useEffect(() => {
    getProfileData()
  }, [getProfileData])

  const postUserData = useCallback(
    (
      formik: User & {
        id: string
        role: string
      }
    ) => {
      const formData = new FormData()

      formData.append('email', formik.email)
      formData.append('username', formik.username)
      formData.append('type', formik.role)
      if (typeof profileImage !== 'string') {
        if (profileImage) {
          formData.append('profile_image', profileImage)
        } else {
          formData.append('profile_image', '')
        }
      }

      fetch(`${BASE_URL}/usage_stats/update_reseller_user/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) throw new Error('Unauthorized')
          handleAlert()
          onClose()
          return response.json()
        })

        .catch((error) => {
          console.error(error)
        })
    },
    [auth?.api_token, id, onClose, profileImage]
  )
  const formik = useFormik<User & {id: string; role: string}>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: modalAddUserSchema,

    onSubmit: (values) => {
      postUserData(values)
    },
  })


  const handleAlertRemove = () => {
    Swal.fire({
      icon: 'success',
      text: 'Deleted successfully!',
      confirmButtonColor: '#009EF7',
    })
  }

  const handleRemoveUser = () => {
    fetch(`${BASE_URL}/usage_stats/delete_reseller_user/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        handleAlertRemove()
        onClose()
        return response.json()
      })

      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <div className='p-6 card' style={{marginTop: '50px'}}>
        <div>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>Edit user</h3>

              <div
                className='btn btn-icon btn-sm btn-active-primary ms-2'
                data-bs-dismiss='modal'
                onClick={onClose}
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className='modal-body '>
              <div>
                <div className='collapse show'>
                  <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div>
                      <div className='col-lg-8 text-start'>
                        <p className=' fs-5'>Avatar</p>
                        <div
                          className='image-input image-input-outline d-flex align-items-center gap-5'
                          data-kt-image-input='true'
                        >
                          <div className='symbol symbol-100px symbol-circle mb-7'>
                            <img
                              src={
                                imageBase64
                                  ? imageBase64
                                  : toAbsoluteUrl('/media/avatars/blank.png')
                              }
                              alt=''
                            />
                          </div>
                          <div
                            className='text-primary position-relative'
                            style={{cursor: 'pointer'}}
                          >
                            Change
                            <input
                              ref={inputRef}
                              id='fileInputRemove'
                              name='profile_image'
                              type='file'
                              accept='.png, .jpeg, .jpg'
                              style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                fontSize: '0',
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                              }}
                              onChange={(e) => {
                                handleFileChange(e)

                                const selectedFile = e.target?.files ? e.target?.files[0] : null
                                setProfileImage(selectedFile)
                                if (selectedFile) {
                                  const reader = new FileReader()
                                  reader.onload = (event) => {
                                    if (event.target) {
                                      const base64String = event.target.result as string
                                      setImageBase64(base64String)
                                    }
                                  }
                                  reader.readAsDataURL(selectedFile)
                                }
                              }}
                            />
                          </div>
                          <div
                            className='text-danger '
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              setImageBase64(null)
                              setProfileImage(null)
                              resetFileInput()
                            }}
                          >
                            Remove
                          </div>
                        </div>
                        <p className='text-muted pt-3 fs-5 text-start'>
                          Allowed file types: .png, .jpeg, .jpg
                        </p>
                      </div>
                      <div className='row mb-2'>
                        <label className='col-lg-12 col-form-label required fw-bold fs-6 text-start'>
                          Full name
                        </label>

                        <div className='col-lg-12'>
                          <div className='row'>
                            <div className='col-lg-12 text-start fv-row'>
                              <input
                                type='text'
                                className='form-control  form-control-lg form-control-solid mb-3 mb-lg-0'
                                placeholder='User name'
                                {...formik.getFieldProps('username')}
                                name='username'
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                id='username'
                              />

                              {formik.touched.username && formik.errors.username && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.username}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-2'>
                        <label className='col-lg-12 col-form-label  required fw-bold fs-6 text-start'>
                          Email address
                        </label>

                        <div className='col-lg-12 text-start fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='email@example.com'
                            {...formik.getFieldProps('email')}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            id='email'
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.email}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <label className='col-lg-12 col-form-label  required fw-bold fs-6 mb-3 text-start'>
                        Role
                      </label>

                      <div className='d-flex mb-5 text-start'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            {...formik.getFieldProps('role')}
                            type='radio'
                            value='Administrator'
                            checked={formik.values.role === 'Administrator'}
                          />
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Administrator</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              All Permissions to their Reseller Account
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Engineer'
                            checked={formik.values.role === 'Engineer'}
                          />
                        </div>
                        <div className='d-flex align-items-center text-start'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Engineer</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Access to Alerts,Storage Usage,any IT related Task, can Edit
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Accounting'
                            checked={formik.values.role === 'Accounting'}
                          />
                        </div>
                        <div className='d-flex align-items-center text-start'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Accounting</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Invoicing only & possibly Company Details edits, Payment Methods
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex mb-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input widget-9-check'
                            type='radio'
                            {...formik.getFieldProps('role')}
                            value='Read-Only'
                            checked={formik.values.role === 'Read-Only'}
                          />
                        </div>
                        <div className='d-flex align-items-center text-start'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold  fs-6'>Read-Only</span>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Access to Alerts,Storage Usage but can't edit
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='text-start'>
                        {formik.submitCount > 0 && !formik.values.role && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>A role is required</div>
                          </div>
                        )}
                      </div>
                    </div>


<div className='d-flex justify-content-between pt-3'>
<div>
<button type='button' className='btn text-danger ' onClick={handleRemoveUser} >
<i className='bi bi-trash3 text-danger '></i>Delete
                      </button>
</div>
                    <div className=' d-flex justify-content-end  gap-1'>
                      <button type='button' className='btn btn-light' onClick={onClose}>
                        Close
                      </button>
                
                      <button type='submit' className='btn btn-primary'>
                        Update user
                      </button>
                    </div>
</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
