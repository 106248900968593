import {useCallback, useState} from 'react'
import {useAuth} from '../app/modules/auth'
import {BASE_URL} from '../utility/constants'

export default function useCallApi() {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const {auth, logout} = useAuth()

  const getData = useCallback(
    async (url: string, method?: string) => {
      setLoading(true)
      setData(undefined)
      try {
        const res = await fetch(`${BASE_URL}/${url}`, {
          method: method ?? 'GET',
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          },
        })
        if (res.status === 401) {
          logout()
          throw new Error('Unauthorized')
        }
        const data = await res.json()
        setData(data)
        setLoading(false)
        return data
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [auth?.api_token]
  )

  return {getData, data, loading}
}
