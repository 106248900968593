/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useInvoiceStore} from '../../../../stores/use-invoice'
import {BASE_URL} from '../../../../utility/constants'
import {KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import {InvoiceDropdown} from './InvoiceDropdown'

type Props = {
  className: string
}

type InvoiceItem = {
  id: string
  created: number
  status: string
  total: number
  account_name: string
  invoice_pdf: any
  amount_due: number
}

const Invoices: React.FC<Props> = ({className}) => {
  const {auth} = useAuth()
  const {updateInvoices, invoicesOfStore} = useInvoiceStore()

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null

  const userID = user.resellerId
  const [selectAll, setSelectAll] = useState(false)

  const [invoice, setInvoice] = useState<InvoiceItem[] | undefined>(undefined)
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([])
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [sortedInvoices, setSortedInvoices] = useState<InvoiceItem[] | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5

  const handleDownload = (invoicePdfUrl: string, invoiceId: string) => {
    const link = document.createElement('a')
    link.href = invoicePdfUrl
    link.download = `invoice_${invoiceId}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const renderInvoices = ({id, invoice_pdf}: InvoiceItem) => (
    <button
      type='button'
      className='btn btn-sm btn-icon btn-color-primary '
      onClick={() => {
        handleDownload(invoice_pdf, id)

      }}
    >
      <i className='bi bi-download me-3'></i>
      Download
    </button>
  )

  const handleSortByDate = () => {
    const sorted = [...(invoice || [])].sort((a, b) => {
      const dateA = new Date(a.created * 1000).getTime()
      const dateB = new Date(b.created * 1000).getTime()

      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
    })

    setSortedInvoices(sorted)
    setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'))
  }

  useEffect(() => {
    setSortedInvoices(invoice)
  }, [invoice])

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectAll(!selectAll)
      setSelectedInvoices(invoice?.map((item) => item.id) || [])
    } else {
      setSelectAll(false)
      setSelectedInvoices([])
    }
  }

  const fetchInvoices = async () => {
    try {
      const response = await fetch(`${BASE_URL}/usage_stats/invoice_list/${userID}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
      })

      if (!response.ok) {
        throw new Error('Unauthorized')
      }

      const data = await response.json()
      setInvoice(data.invoices)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchInvoices()
  }, [auth, userID, updateInvoices])

  const handleSelectInvoice = (invoiceId: string) => {
    const updatedSelection = selectedInvoices.includes(invoiceId)
      ? selectedInvoices.filter((id) => id !== invoiceId)
      : [...selectedInvoices, invoiceId]

    setSelectAll(updatedSelection.length === invoice?.length)
    setSelectedInvoices(updatedSelection)
  }

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${day}/${month}/${year}`
  }

  const paginatedInvoices = sortedInvoices
    ? sortedInvoices.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : []

  const totalPages = Math.ceil((sortedInvoices?.length || 0) / itemsPerPage)

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    fetchInvoices()
  }, [invoicesOfStore])

  return (
    <div className='card '>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title'>Invoices</h3>
      </div>
      <div className='card-body '>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_7' role='tabpanel'>
            <div className='table-responsive'>
              <table className='table gs-7 gy-7 gx-7'>
                <thead>
                  <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th className='d-flex gap-5'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid' >
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{cursor:"pointer"}}
                        />
                      </div>
                      Invoice No.
                    </th>
                    <th onClick={handleSortByDate}>
                      Date{' '}
                      <i
                        style={{cursor: 'pointer'}}
                        className={`bi bi-arrow-down-up ${sortOrder === 'asc' ? 'up' : 'down'}`}
                      ></i>
                    </th>
                    <th>Status</th>
                    <th>Amount </th>
                    <th></th>
                    <th className='text-end'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedInvoices?.flatMap((item, i) => (
               
                    <tr key={item.id}>
                      <td className='d-flex gap-5'>
                        {' '}
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value={item.id}
                            checked={selectedInvoices.includes(item.id)}
                            onChange={() => handleSelectInvoice(item.id)}
                            style={{cursor:"pointer"}}
                          />
                        </div>
                        {item.id}
                      </td>
                      <td>{formatDate(item.created)}</td>
                      <td>
                        {' '}
                        <span
                          className={`fw-bold  ${
                            item.status === 'paid'
                              ? 'bg-light-success text-success'
                              : item.status === 'open'
                              ? 'bg-light-warning text-warning'
                              : 'bg-light-danger text-danger'
                          } p-2 rounded`}
                        >
                          {item.status}
                        </span>
                      </td>

                      <td className='fw-bold'>${Math.round(item.total * 0.01 * 100) / 100}</td>

                      <td className='text-end'>{renderInvoices(item)}</td>

                      <div className='dropdown text-end '>
                        <div className='btn ' id='dropdownMenuButton1' data-bs-toggle='dropdown'>
                          {' '}
                          <KTSVG
                            path='/media/icons/duotune/general/gen053.svg'
                            className='svg-icon-muted svg-icon-hx'
                          />
                        </div>
                        <InvoiceDropdown
                          details={item}
                          id={item.id}
                          handleClose={() => fetchInvoices()}
                          toRemove={item.status === 'draft'}
                          fetchInvoices={fetchInvoices}
                        />
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='d-flex  w-100 justify-content-end '>
                <div className=' flex-stack  flex-wrap pt-10'>
                  <ul className='pagination'>
                    <li className='page-item previous' style={{cursor:"pointer"}} >
                      <div className='page-link' onClick={() => handlePageClick(currentPage - 1)}>
                        <i className='fa-solid fa-angles-left'></i>
                      </div>
                    </li>

                    {Array.from({length: totalPages}, (_, index) => (
                      <li
                        className={index + 1 === currentPage ? 'active' : ''}
                        key={index}
                        style={{cursor: 'pointer'}}
                      >
                        <div className='page-link' onClick={() => handlePageClick(index + 1)}>
                          {index + 1}
                        </div>
                      </li>
                    ))}

                    <li className='page-item next' style={{cursor:"pointer"}}>
                      <div className='page-link' onClick={() => handlePageClick(currentPage + 1)}>
                        <i className='fa-solid fa-angles-right'></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Invoices}
