import moment from 'moment'
import {useEffect, useState} from 'react'
import {useAuth} from '../../../../../app/modules/auth'
import {BASE_URL} from '../../../../../utility/constants'
import useCallApi from '../../../../../apis/api'
import { useLoading } from '../../../../../stores/useLoading'

export default function SidebarMenuSync() {
  const {auth, logout, currentUser} = useAuth()
  const {data, getData} = useCallApi()
  const {loading,setLoading}=useLoading();

  useEffect(() => {
    getData('usage_stats/resellers_sync/')
  }, [])

  const handleSync = () => {
    setLoading(true)
    fetch(`${BASE_URL}/usage_stats/resellers_sync/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then(() => {
        getData('usage_stats/resellers_sync/')
        setLoading(false)
      })
      .catch((e) => {
        console.log(e.message)
      })
  }

  return (
    <>
      {currentUser?.groups?.toString() === 'reseller' ? (
        <>
         
        </>
      ) 
       :currentUser?.groups?.toString() === 'reseller_user' ? 
       <>
         
        </>
        : (
          <>
          <div className='d-flex justify-content-center '>
            {loading ? (
              <div className='spinner-border text-primary' role='status'></div>
            ) : (
              <button
                onClick={handleSync}
                type='button'
                className='btn btn-primary btn-sm btn btn-primary'
              >
                Sync
              </button>
            )}
          </div>
          <div className='pt-2  text-white'>
            <p>{moment(data?.latest_date).fromNow()}</p>
          </div>
        </>
      )}
    </>
  )
}
