import React, {useEffect, useState} from 'react'
import useCallApi from '../../../apis/api'
import {ResellersStat} from '../../../types/reseller'
import {getHumanValueStorage} from '../../../utility/utils'

export default function ResellersHeadingDetail() {
  const [reseller, setReseller] = useState<ResellersStat>()
  const [clients, setClients] = useState([])
  const {getData,} = useCallApi()
  const userJSON = localStorage.getItem('user') 
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId

  useEffect(() => {
    if (resellerUid) {
      getData(`usage_stats/resellers_stats/` + resellerUid).then((data) => {
        if (data.clients.length !== 0) {
          setClients(
            data?.clients?.sort((a: {name: string}, b: {name: any}) => a.name.localeCompare(b.name))
          )
        } else {
          setClients([])
        }
        setReseller(data?.reseller)
        console.log('data', data)
      })
    }
  }, [getData, resellerUid])

  return (
    <div className='row  '>
      <div className='col-xl-3 d-flex flex-column'>
        <div className='d-flex gap-3 mb-4'>
          <div className='card col-6 p-5 min-h-100px max-h-100px d-flex flex-column flex-fill'>
            <div className='  d-flex   gap-3'>
              <div>
                <i className='bi bi-inbox-fill fs-5 text-primary'></i>
              </div>
              <div>
                <p className=' text-muted '>Storage Usage</p>

                <h3 className='fs-6 fs-lg-3'>
                  {' '}
                  {reseller ? getHumanValueStorage(reseller?.storage_usage) : 'N/A'}
                </h3>
              </div>
            </div>
          </div>
          <div className='card col-6 p-5 min-h-100px d-flex flex-column flex-fill'>
            <div className='d-flex gap-3'>
              <div>
                <i className='bi bi-shield-check fs-6 text-warning'></i>
              </div>
              <div>
                <p className=' text-muted'> B & R enterprise</p>

                <h3 className='text-warning'>
                  {reseller ? reseller.br_enterprise + reseller.br_enterprise_new : 'N/A'}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex gap-3'>
          <div className='card col-6 p-5 min-h-100px max-h-100px d-flex flex-column flex-fill'>
            <div className='d-flex gap-3'>
              <div>
                <i className='bi bi-graph-up text-danger fs-6'></i>
              </div>
              <div>
                <p className=' text-muted'> Managed Workload</p>

                <h3 className='text-danger'>
                  {clients.length !== 0
                    ? `${clients?.reduce(
                        (sum, stat) =>
                        // @ts-ignore
                          sum + (stat?.is_managed ? stat?.br_enterprise + stat?.br_enterprise_new : 0),
                        0
                      ) +
                      clients?.reduce(
                        (sum, stat) =>
                          sum +
                          // @ts-ignore
                          (stat?.is_managed
                            // @ts-ignore
                            ? stat?.br_enterprise_plus + stat?.br_enterprise_plus_new
                            : 0),
                        0
                      ) +
                      clients?.reduce(
                        (sum, stat) =>
                          sum +
                          // @ts-ignore
                          (stat?.is_managed
                            // @ts-ignore
                            ? stat?.veeam_server_agent + stat?.veeam_server_agent_new
                            : 0),
                        0
                      )}`
                    : '0'}
                </h3>
              </div>
            </div>
          </div>
          <div className='card col-6 p-5 min-h-100px d-flex flex-column flex-fill'>
            <div className='d-flex gap-3'>
              <div>
                <i className='bi bi-shield-plus fs-6 text-primary'></i>
              </div>
              <div>
                <p className=' text-muted'> B & R enterprise+</p>
                <h3>
                  {reseller ? reseller.br_enterprise_plus + reseller.br_enterprise_plus_new : 'N/A'}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-xl-9 mt-5 mt-xl-0 d-flex flex-column'>
        <div className='card d-flex flex-column flex-fill '>
          <div className='row mx-5 mb-3  min-h-100px '>
            <div className='col-3  mt-5'>
              <p className=' text-muted'> Veeam Server Agent</p>

              <h3>
                {reseller ? reseller.veeam_server_agent + reseller.veeam_server_agent_new : 'N/A'}{' '}
              </h3>
            </div>

            <div className='col-3 pt-5'>
              <p className=' text-muted'> Veeam Workstation Agent</p>

              <h3>
                {reseller
                  ? reseller.veeam_workstation_agent + reseller.veeam_workstation_agent_new
                  : 'N/A'}{' '}
              </h3>
            </div>
            <div className='col-3 pt-5 '>
              <p className=' text-muted'>Veeam Backup for M365</p>
              <h3>
                {reseller
                  ? reseller.veeam_backup_for_m365 + reseller.veeam_backup_for_m365_new
                  : 'N/A'}{' '}
              </h3>
            </div>
            <div className='col-3  pt-5'>
              <p className=' text-muted'>Veeam Backup</p>
              <h3>0</h3>
            </div>
          </div>

          <div className='row mx-5  min-h-100px '>
            <div className='col-3 pt-5 '>
              <div
                className='border-top border-secondry border-2 '
                style={{width: '45px', marginTop: '-20px', marginBottom: '20px'}}
              ></div>
              <p className=' text-muted'>Veeam Cloud Backup (Server)</p>
              <h3>
                {reseller
                  ? reseller.veeam_cloud_connect_server + reseller.veeam_cloud_connect_server_new
                  : 'N/A'}{' '}
              </h3>
            </div>
            <div className='col-3 pt-5 '>
              <div
                className='border-top border-secondry border-2 '
                style={{width: '45px', marginTop: '-20px', marginBottom: '20px'}}
              ></div>
              <p className=' text-muted'>Veeam Cloud Backup (Workstation)</p>
              <h3>
                {reseller
                  ? reseller.veeam_cloud_connect_workstation +
                    reseller.veeam_cloud_connect_workstation_new
                  : 'N/A'}{' '}
              </h3>
            </div>
            <div className='col-3 pt-5 '>
              <div
                className='border-top border-secondry border-2 '
                style={{width: '45px', marginTop: '-20px', marginBottom: '20px'}}
              ></div>
              <p className=' text-muted'>Veeam Cloud Backup (VM)</p>
              <h3>
                {reseller
                  ? reseller.veeam_cloud_connect_vm + reseller.veeam_cloud_connect_vm_new
                  : 'N/A'}{' '}
              </h3>
            </div>
            <div className='col-3 pt-5 '>
              <div
                className='border-top border-secondry border-2 '
                style={{width: '45px', marginTop: '-20px', marginBottom: '20px'}}
              ></div>
              <p className=' text-muted'>Replication Standard License Usage</p>
              <h3>0</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
