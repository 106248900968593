import { ReactNode } from "react";
import { createPortal } from "react-dom";

function ModalShow({
  content,
  onClose,
  showModal,
  title
}: {
  content: ReactNode;
  onClose: () => void;
  showModal: boolean;
  title?: ReactNode | string;
}) {
  return (
    <>
      {showModal &&
        createPortal(
          <div className="modal-root">
            <div className="modalshow">
              <div>
                <section
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                  }}
                >
                  <h2>{title ? title : null}</h2>
                </section>
                <div>{content } </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
export default ModalShow;
