import {Field, Formik, useFormik} from 'formik'
import {useState} from 'react'
import {inits} from '../../wizards/components/CreateAccountWizardHelper'
import {KTSVG} from '../../../../_metronic/helpers'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'
import * as Yup from 'yup'

export default function EditPayment({id, onClose , isPrimary}: {id: string; onClose: () => void ; isPrimary:boolean}) {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {auth} = useAuth()

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const userID = user.resellerId

  interface IPaymentMethod {
    cardCvv?: string
    cardExpiryYear?: string
    cardExpiryMonth?: string
    setAsPrimary?: boolean

    [key: string]: string | boolean | undefined
  }
  interface ICreateAccount {
    cardCvv?: string
    cardExpiryYear?: string
    cardExpiryMonth?: string
    setAsPrimary?: boolean
    [key: string]: string | boolean | undefined
  }

  const initialValues: ICreateAccount = {}

  const isFieldEdited = (fieldName: string) => {
    return formik.values[fieldName] !== formik.initialValues[fieldName]
  }

  const formik = useFormik<IPaymentMethod>({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      cardExpiryMonth: Yup.string().matches(/^([1-9]|0[1-9]|1[0-2])$/, 'Invalid month'),
      cardExpiryYear: Yup.string()
        .length(4, 'Year must be 4 digits')
        .matches(/^(20\d{2}|2[2-9]\d{1})$/, 'Invalid year'),
    }),

    onSubmit: (values) => {
      setIsSubmitted(true)

      const editedFields: any = {}

      if (isFieldEdited('cardCvv')) {
        if (formik.values.cardCvv !== '') {
            editedFields.card_cvv = formik.values.cardCvv

        }
        
      }
      if (isFieldEdited('cardExpiryYear')) {
        if (formik.values.cardExpiryYear !== '') {
          editedFields.card_exp_year = formik.values.cardExpiryYear;
        }
      }
      if (isFieldEdited('cardExpiryMonth')) {
        if (formik.values.cardExpiryMonth !== '') {
          editedFields.card_exp_month = formik.values.cardExpiryMonth;
        }
      }
      if (isFieldEdited('setAsPrimary')) {
        editedFields.set_default = formik.values.setAsPrimary
      }

      if (Object.keys(editedFields).length > 0) {
        fetch(`${BASE_URL}/usage_stats/customer_card_details/${userID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.api_token}` ?? '',
          },
          body: JSON.stringify({
            ...editedFields,
            payment_method_id: id,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              return response.json().then((data) => {
                throw new Error(data.error)
              })
            }
            onClose()
            return response.json()
          })
          .catch((error) => {
            console.error(error.message)
          })
      }
    },
  })

  return (
    <>
      <div className='px-10 py-5 card w-lg-75 w-xl-50 m-auto' style={{marginTop: '50px'}}>
        <div className=''>
          <div className='modal-content '>
            <div className='modal-header py-5'>
              <h3 className='modal-title'>Edit Card</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={onClose}
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className=' py-10'>
              <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                <Formik
                  validateOnMount={true}
                  initialValues={inits}
                  onSubmit={() => formik.handleSubmit()}
                >
                  <form
                    className=' w-100 w-xl-700px px-2'
                    noValidate
                    id='kt_create_account_form'
                    onSubmit={formik.handleSubmit}
                  >
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='row mb-10'>
                          <div className='col-md-12 fv-row'>
                            <label className=' fs-6 fw-bold form-label mb-2'>Expiration Date</label>

                            <div className='row fv-row '>
                              <div className=' d-flex justify-content-between '>
                                <div className='col-md-5'>
                                  <Field
                                    type='text'
                                    {...formik.getFieldProps('cardExpiryMonth')}
                                    name='cardExpiryMonth'
                                    className={`form-control ${
                                      formik.errors.cardExpiryMonth &&
                                      formik.touched.cardExpiryMonth
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    maxLength={2}
                                    placeholder='MM'
                                    onKeyPress={(e: any) => {
                                      if (isNaN(Number(e.key))) {
                                        e.preventDefault()
                                      }
                                    }}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    {formik.errors?.cardExpiryMonth &&
                                    formik.touched.cardExpiryMonth
                                      ? formik.errors.cardExpiryMonth
                                      : null}
                                  </div>
                                </div>
                                <div className='col-md-5'>
                                  <Field
                                    type='text'
                                    {...formik.getFieldProps('cardExpiryYear')}
                                    name='cardExpiryYear'
                                    className={`form-control ${
                                      formik.errors.cardExpiryYear && formik.touched.cardExpiryYear
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    maxLength={4}
                                    placeholder='YYYY'
                                    onKeyPress={(e: any) => {
                                      if (isNaN(Number(e.key))) {
                                        e.preventDefault()
                                      }
                                    }}
                                  ></Field>
                                  <div className='text-danger mt-2'>
                                    {formik.errors?.cardExpiryYear && formik.touched.cardExpiryYear
                                      ? formik.errors.cardExpiryYear
                                      : null}
                                  </div>
                                </div>
                                <div className='text-danger mt-2'>
                                  {formik.errors?.cardExpiryYear && isSubmitted
                                    ? formik.errors?.cardExpiryYear
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row d-flex justify-content-between'>
                          <div className='col-md-5 '>
                            <label className='d-flex  fs-6 fw-bold form-label mb-2'>
                              <span className=''>CVV</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Enter a card CVV code'
                              ></i>
                            </label>

                            <div className='position-relative'>
                              <Field
                                type='text'
                                className='form-control '
                                maxLength={3}
                                placeholder='CVV'
                                {...formik.getFieldProps('cardCvv')}
                                name='cardCvv'
                                onKeyPress={(e: any) => {
                                  if (isNaN(Number(e.key))) {
                                    e.preventDefault()
                                  }
                                }}
                              />
                              <div className='text-danger mt-2'>
                                {formik.errors?.cardCvv && isSubmitted
                                  ? formik.errors?.cardCvv
                                  : null}
                              </div>

                              <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                                {/* <KTSVG
                                  path='/media/icons/duotune/finance/fin002.svg'
                                  className='svg-icon-2hx'
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 '>
                            <label className=' fs-6 fw-bold form-label '>Set As Primary</label>
                            <div className='form-check form-switch d-flex  align-items-center  h-50'>
                              <input

                                className='form-check-input'
                                type='checkbox'
                                id='flexSwitchCheckDefault'
                                {...formik.getFieldProps('setAsPrimary')}
                                checked={formik.values.setAsPrimary || isPrimary} 
                                disabled={isPrimary}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='modal-footer d-flex gap-4 mt-8'>
                      <button type='button' className='btn btn-light' onClick={onClose}>
                        Close
                      </button>
                      <button
                        type='submit'
                        onClick={(e) => {
                          setIsSubmitted(true)
                        }}
                        className='btn btn-primary'
                      >
                        Edit Card
                      </button>
                    </div>
                  </form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
