import React, {FormEvent, useState} from 'react'
import {PaymentElement, useStripe, useElements, CardElement} from '@stripe/react-stripe-js'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'

function CheckoutForm({handleGetPaymentDetail}:any) {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const {auth} = useAuth()
  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const userID = user.resellerId


  const checkPrimaryCard = (paymentMethodId: string) => {
    fetch(`${BASE_URL}/usage_stats/set_default_card/${userID}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
      body: JSON.stringify({
        payment_method_id: paymentMethodId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error)
          })
        }
        return response.json()
      })

      .then((data) => {
        console.log('data', data)
      })
      .catch((error) => {
        console.error(error.message)
      })
  
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()

    if (!stripe || !elements) {
      setIsLoading(false)
      return null
    }

    const res = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    console.log('res', res.setupIntent)
    if (res.error) {
      setIsLoading(false)
      setErrorMessage(res.error.message)
    } else {
      const paymentMethod = res.setupIntent.payment_method as string
      checkPrimaryCard(paymentMethod)
      handleGetPaymentDetail()
      // setIsLoading(false)
      
      setTimeout(() => {
        setIsLoading(false)
        window.location.reload()
      }, 800);
      
    }
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement
        className='text-danger'
        id='payment-element'
        options={{
          terms: {
            card: 'never',
          },
        }}
        
      />

      <button
        disabled={isLoading || !stripe || !elements}
        id='submit'
        type='submit'
        className='btn btn-primary float-end mb-2 mt-8'
        aria-label='Close'
      >
        <span id='button-text'>
          {isLoading ? (
            <div className='spinner' id='spinner'>
              loading
            </div>
          ) : (
            'Add Card'
          )}
        </span>
      </button>

      {message && <div id='payment-message'>{message}</div>}
      {errorMessage && (
        <div id='error' style={{color: 'red'}}>
          {errorMessage}
        </div>
      )}
    </form>
  )
}

export {CheckoutForm}
