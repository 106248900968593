import {useFormik} from 'formik'
import React, {useState} from 'react'
import * as Yup from 'yup'
import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import clsx from 'clsx'

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export default function StartTrial() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })

          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })
  return (
    <div className='container '>
      <div className='row d-flex align-items-center  ' style={{height: '70vh'}}>
        <div className='col ms-5'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className=' mb-10'>
              <h1 className=' fw-bolder mb-3 text-primary'> Account Settings</h1>

              <div className='text-gray-500 fw-semibold fs-6'></div>
            </div>

            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Sent password reset. Please check your email</div>
              </div>
            )}
            {}
            <div className='row '>
              <div className='col'>
                {' '}
                <label className='form-label required fw-bolder text-gray-900 fs-6'>
                  First Name
                </label>
                <input
                  type='text'
                  placeholder='First Name'
                  autoComplete='off'
                  className={clsx('form-control bg-transparent')}
                />
              </div>
              <div className='col'>
                {' '}
                <label className='form-label required fw-bolder text-gray-900 fs-6'>
                  Last Name
                </label>
                <input
                  type='text'
                  placeholder='Last Name'
                  autoComplete='off'
                  className={clsx('form-control bg-transparent')}
                />
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col'>
                {' '}
                <label className='form-label required fw-bolder text-gray-900 fs-6'>Password</label>
                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx('form-control bg-transparent')}
                  name='password'
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                )}
              </div>
              <div className='col'>
                {' '}
                <label className='form-label required fw-bolder text-gray-900 fs-6'>
                  Confirm Password
                </label>
                <input
                  type='password'
                  placeholder='Confirm Password'
                  autoComplete='off'
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx('form-control bg-transparent')}
                  name='confirmPassword'
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.confirmPassword}</span>
                  </div>
                )}
              </div>
            </div>

            <h1 className=' fw-bolder mb-3 text-primary my-5 py-5'> Payment Method</h1>

            <div className='row mt-4'>
              <div className='col'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 '>
                  <span className='required'>Card Number</span>
                </label>

                <div className='position-relative'>
                  <input
                    type='text'
                    className={clsx('form-control bg-transparent')}
                    placeholder='Enter card number'
                    name='cardNumber'
                  />

                  <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                      alt=''
                      className='h-25px'
                    />
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                      alt=''
                      className='h-25px'
                    />
                    <img
                      src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                      alt=''
                      className='h-25px'
                    />
                  </div>
                </div>
              </div>
              <div className='col'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>Name On Card</span>
                </label>

                <input
                  type='text'
                  className='form-control bg-transparent '
                  placeholder='Name'
                  name='nameOnCard'
                />
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col'>
                {' '}
                <label className='form-label required fw-bolder text-gray-900 fs-6'>
                  Expired Date
                </label>
                <input
                  type='text'
                  placeholder='MM/YY'
                  autoComplete='off'
                  className={clsx('form-control bg-transparent')}
                />
              </div>
              <div className='col'>
                {' '}
                <label className='form-label required fw-bolder text-gray-900 fs-6'>CVV</label>
                <input
                  type='number'
                  placeholder='CVV'
                  autoComplete='off'
                  className={clsx('form-control bg-transparent')}
                />
              </div>
            </div>

            <div className='d-flex flex-wrap justify-content-between align-items-center  pb-lg-0 mt-5 pt-5'>
              <div className='fw-bold text-primary'>You have 14 free trial days & 20TB</div>
              <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                <span className='indicator-label'>Start Trial</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
