import React from 'react'
import {cardImages} from '../../../../utility/cardsImages'
import {KTSVG} from '../../../../_metronic/helpers'
import {DropdownPayment} from './DropdownPayment'

type Card = {
  brand: string
  name: string
  status?: string
  path: string
  card_number: string
  card_exp_month: string
  card_exp_year: string
  payment_method_id: string
  default_payment_method_id: string
}

export default function PaymentMethodCard({
  reverseCards,
  resName,
  defaultPaymentMethodId,
  getPaymentCardsDetail,
  defaultCard,
}: {
  reverseCards: Card[]
  resName: string | undefined
  defaultPaymentMethodId: string
  getPaymentCardsDetail: () => void
  defaultCard: string
}) {
  return (
    <div className='card-body py-3'>
      <div className='table-responsive overflow-hidden'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
          <tbody>
            <tr>
              <td>
                <div className='row  '>
                  {reverseCards.map((item, i) => (
                    <div key={i} className='col-md-6 col-xl-6 mb-5'>
                      <div className=' p-2 border rounded'>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex gap-2 p-2'>
                            <h3>{resName}</h3>

                            {item.payment_method_id === defaultPaymentMethodId && (
                              <span className={`fw-bold p-1 bg-light-primary text-primary`}>
                                Primary
                              </span>
                            )}
                          </div>
                          <div className='dropdown'>
                            <div
                              className='btn  '
                              id='dropdownMenuButton1'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              {' '}
                              <KTSVG
                                path='/media/icons/duotune/general/gen053.svg'
                                className='svg-icon-muted svg-icon-hx'
                              />
                            </div>
                            <DropdownPayment
                              id={item.payment_method_id}
                              handleClose={() => getPaymentCardsDetail()}
                              defaultCard={defaultCard}
                              isPrimary={item.payment_method_id === defaultPaymentMethodId}
                            />
                          </div>
                        </div>

                        <div className='d-flex  align-items-center fs-6 gap-2 mb-2'>
                          <img
                            src={cardImages[item.brand]}
                            alt=''
                            className='card-image '
                            style={{height: '50px'}}
                          />

                          <div>
                            <div className='d-flex gap-2 fw-bold'>
                              <div>
                                {item.brand.charAt(0).toUpperCase() + item.brand.slice(1)}{' '}
                                {String(item.card_number).slice(-9)}
                              </div>
                            </div>
                            <div className=''>
                              Expires at {item.card_exp_month}/
                              {String(item.card_exp_year).slice(-2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
