/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useEffect, useState} from 'react'

const SidebarMenuMain = () => {
  const { currentUser} = useAuth()
  const [filterDate, setFilterDate] = useState('')


  useEffect(() => {
    const now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();
  
    if (month === 0) {
      month = 12; 
      year -= 1; 
    }
  
    setFilterDate(
      month < 10 ? `0${month}-${year}` : `${month}-${year}`
    );
  }, []);

  return (
    <>
      {currentUser?.groups?.toString() === 'reseller' ? (
        <>
          <SidebarMenuItem
            to={`/dashboard`}
            title={'Overview Dashboard'}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItemWithSub
            to={`resellers/${currentUser.resellerId}`}
            title='Reports'
            hasBullet={true}
          >
            <>
              <SidebarMenuItem
                to={`resellers/${currentUser.resellerId}`}
                icon='/media/icons/duotune/communication/com006.svg'
                title={'Reseller'}
                fontIcon='bi-app-indicator'
              />
        
              <SidebarMenuItem
                to={`/individual-monthly-licensing/${filterDate}/${currentUser.resellerId}`}
                icon='/media/icons/duotune/files/fil001.svg'
                title={'Monthly Billing Report'}
                fontIcon='bi-app-indicator'
              />
            </>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to={`manage-managed-clients/${currentUser.resellerId}`}
            title='Management'
            hasBullet={true}
          >
            <SidebarMenuItem
              to={`manage-managed-clients/${currentUser.resellerId}`}
              title='Manage Managed Clients'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </>
      ) : currentUser?.groups?.toString() === 'reseller_user' ? (
        <>
          <SidebarMenuItem
            to={`/dashboard`}
            title={'Overview Dashboard'}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItemWithSub
            to={`resellers/${currentUser.resellerId}`}
            title='Reports'
            hasBullet={true}
          >
            <>
              <SidebarMenuItem
                to={`resellers/${currentUser.resellerId}`}
                icon='/media/icons/duotune/communication/com006.svg'
                title={'Reseller'}
                fontIcon='bi-app-indicator'
              />

              <SidebarMenuItem
                to={`/individual-monthly-licensing/${filterDate}/${currentUser.resellerId}`}
                icon='/media/icons/duotune/files/fil001.svg'
                title={'Monthly Billing Report'}
                fontIcon='bi-app-indicator'
              />
            </>
          </SidebarMenuItemWithSub>

          
        </>
      ) : (
        /////////ADMIN////////////
        <>
          <SidebarMenuItemWithSub to='/resellers/' title='Reports' hasBullet={true}>
            <SidebarMenuItem
              to={`/resellers/`}
              icon='/media/icons/duotune/communication/com006.svg'
              title={'Reseller'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='monthly-licensing-graphs'
              icon='/media/icons/duotune/files/fil002.svg'
              title={'Monthly Licensing Report'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/licensing-billing-report'
              icon='/media/icons/duotune/files/fil001.svg'
              title={'Monthly Billing Report'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/protected-data-report'
              icon='/media/icons/duotune/files/fil003.svg'
              title={'Protected Data Report'}
              fontIcon='bi-app-indicator'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub to='manage-managed-clients/' title='Management' hasBullet={true}>
            <SidebarMenuItem
              to='manage-managed-clients/'
              title='Manage Managed Clients'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItem
              to='default-cost/'
              title='Payment Settings'
              hasBullet={true}
            />
           
           
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
