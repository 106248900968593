/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import ModalPaymentMethod from './ModalPaymentMethod'
import {useAuth} from '../../auth'
import {BASE_URL} from '../../../../utility/constants'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import Swal from 'sweetalert2'
import PendingInvoice from './PendingInvoice'
import {useInvoiceStore} from '../../../../stores/use-invoice'
import PaymentMethodCard from './PaymentMethodCard'

const stripePromise = loadStripe('pk_test_tx1u39nvWCmAwqrHb1YeNb9J')

type Props = {
  className: string
}

type Card = {
  brand: string
  name: string
  status?: string
  path: string
  card_number: string
  card_exp_month: string
  card_exp_year: string
  payment_method_id: string
  default_payment_method_id: string
}

type InvoiceItem = {
  id: string
  created: number
  status: string
  total: number
  account_name: string
}

const PaymentMethod: React.FC<Props> = ({className}) => {
  const {auth} = useAuth()
  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId
  const {invoicesOfStore} = useInvoiceStore()

  const group = user.groups
  const [data, setData] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [cardLength, setCardLength] = useState<number>()
  const [invoice, setInvoice] = useState<InvoiceItem[] | undefined>(undefined)

  const [cards, setCards] = useState<Card[]>([])
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('')
  const [resName, setResName] = useState<string>()
  const {updateInvoices} = useInvoiceStore()

  const defaultCard = ''

  const reverseCards = useMemo(() => [...cards].reverse(), [cards])

  const getPaymentCardsDetail = () => {
    fetch(`${BASE_URL}/usage_stats/customer_card_details/${resellerUid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((data) => {
        setDefaultPaymentMethodId(data.default_payment_method_id)
        setCards(data.cards)
        setCardLength(data.cards.length)
      })

      .catch((error) => {
        console.error(error)
      })
  }

  const getProfileData = useCallback(() => {
    fetch(`${BASE_URL}/usage_stats/reseller_profile/${resellerUid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((data) => {
        setResName(data.reseller.name)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [auth?.api_token, resellerUid])

  useEffect(() => {
    getProfileData()
  }, [getProfileData])

  useEffect(() => {
    getPaymentCardsDetail()
  }, [])

  useEffect(() => {
    if (defaultPaymentMethodId === null || defaultPaymentMethodId === undefined) {
      getPaymentCardsDetail()
    }
  }, [defaultPaymentMethodId])

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Payment successfull!',
      confirmButtonColor: '#009EF7',
    })
  }

  const getPaymentAmount = useCallback(() => {
    fetch(`${BASE_URL}/usage_stats/reseller_profile/${resellerUid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((fetchedData) => {
        setData(fetchedData.reseller)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [auth?.api_token, resellerUid])

  const postPaymentAmount = () => {
    setLoading(true)
    fetch(`${BASE_URL}/usage_stats/pay_total_amount/${resellerUid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        setData('$0.00')
        handleAlert()
        getPaymentAmount()
        setLoading(false)
        updateInvoices()

        return response.json()
      })

      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getPaymentAmount()
  }, [getPaymentAmount])

  const handlePendingInvoice = async () => {
    try {
      const response = await fetch(`${BASE_URL}/usage_stats/pending_invoices/${resellerUid}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth?.api_token}` ?? '',
        },
      })

      if (!response.ok) {
        throw new Error('Unauthorized')
      }

      const data = await response.json()
      setInvoice(data.invoices)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getPaymentAmount()
  }, [getPaymentAmount, invoicesOfStore])

  return (
    <>
      {group === 'reseller' && (
        <div className='card mb-5 p-6 px-8 '>
          <div className=' fw-bold fs-3 d-flex justify-content-between align-items-center'>
            {data.billing_amount !== undefined
              ? ` Billable amount : $${Math.round(data.billing_amount * 0.01 * 100) / 100}`
              : ` Billable amount :  Loading...`}
            <div className=''>
              <button
                onClick={postPaymentAmount}
                className='btn btn-primary ms-3 btn-sm'
                disabled={cardLength === 0 || data.billing_amount <= 0}
              >
                {loading ? (
                  <div className='spinner-border ' style={{height: '15px', width: '15px'}}></div>
                ) : (
                  <div className='fw-bold '> Pay Total Amount</div>
                )}
              </button>
              <button
                type='button'
                className=' fw-bold btn btn-primary btn-sm ms-3'
                onClick={handlePendingInvoice}
                data-bs-toggle='modal'
                data-bs-target='#pendingInvoice'
                disabled={data.billing_amount <= 0}
              >
                Pending Invoices
              </button>
              <PendingInvoice
                invoice={invoice}
                handlePendingInvoice={handlePendingInvoice}
                getPaymentAmount={getPaymentAmount}
                cardLength={cardLength}
                amountZero={data.billing_amount}
              />
            </div>
          </div>
        </div>
      )}

      <div className={`card ${className}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Payment Methods</span>
          </h3>

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Add new card
            </a>
          </div>
          <Elements stripe={stripePromise}>
            <ModalPaymentMethod handleGetPaymentDetail={getPaymentCardsDetail} />
          </Elements>
        </div>

       
        <PaymentMethodCard
          reverseCards={reverseCards}
          resName={resName}
          defaultPaymentMethodId={defaultPaymentMethodId}
          getPaymentCardsDetail={getPaymentCardsDetail}
          defaultCard={defaultCard}
        />
      </div>
    </>
  )
}

export {PaymentMethod}
