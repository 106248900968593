import React, {ChangeEvent} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

function ModalProfileForm({
  formik,
  imageBase64,
  inputRef,
  handleFileChange,
  setProfileImage,
  setImageBase64,
  resetFileInput,
}:{
  formik: any,
  imageBase64: string | null,
  inputRef: React.LegacyRef<HTMLInputElement> | undefined,
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void,
  setProfileImage: {(value: React.SetStateAction<File | null>): void; (arg0: File | null): void},
  setImageBase64: {(value: React.SetStateAction<string | null>): void; (arg0: string | null): void},
  resetFileInput: () => void
}
  
) {
  return (
    <div className=' px-3 '>
      <div className=' px-3 '>
        <div id='kt_account_profile_details' className='collapse show'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className='form'
            encType='multipart/form-data'
          >
            <div className='row '>
              <div className='col-lg-8'>
                <p className='fs-5'>Avatar</p>
                <div className='d-flex align-items-center   gap-5'>
                  <div className='symbol symbol-100px symbol-circle mb-7'>
                    <img src={imageBase64 ?? toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
                  </div>

                  <div
                    className='text-primary ml-5 position-relative pe-auto'
                    style={{cursor: 'pointer'}}
                  >
                    Change
                    <input
                      ref={inputRef}
                      id='profile_image'
                      name='profile_image'
                      type='file'
                      accept='.png, .jpeg, .jpg'
                      style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        fontSize: '0',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      }}
                      onChange={(e) => {
                        handleFileChange(e)
                        const selectedFile = e.target?.files ? e.target?.files[0] : null
                        setProfileImage(selectedFile)
                        if (selectedFile) {
                          const reader = new FileReader()
                          reader.onload = (event) => {
                            if (event.target) {
                              const base64String = event.target.result as string
                              setImageBase64(base64String)
                            }
                          }
                          reader.readAsDataURL(selectedFile)
                        }
                      }}
                    />
                  </div>
                  <div
                    className=' px-3 text-danger '
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setImageBase64(null)
                      setProfileImage(null)
                      resetFileInput()
                    }}
                  >
                    Remove
                  </div>
                </div>

                <p className='text-muted pt-3 fs-5'>Allowed file types: .png, .jpeg, .jpg</p>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>Company name</label>

              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      disabled
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Company name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      id='name'
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-12 col-form-label  fw-bold fs-6'>Account Id</label>

              <div className='col-lg-12 fv-row'>
                <input
                  disabled
                  type='text'
                  name='id'
                  className='form-control form-control-lg form-control-solid '
                  placeholder='Account Id'
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  id='id'
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span>Email</span>
              </label>

              <div className='col-lg-12 fv-row'>
                <input
                  type='email'
                  name='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='email@example.com'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  id='email'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Account Owner Contact</span>
              </label>

              <div className='col-lg-12 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='123456789'
                  value={formik.values.account_owner_contact}
                  onChange={formik.handleChange}
                  id='account_owner_contact'
                />
                {formik.touched.account_owner_contact && formik.errors.account_owner_contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.account_owner_contact}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Billing Contact</span>
              </label>

              <div className='col-lg-12 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='123456789'
                  value={formik.values.billing_contact}
                  onChange={formik.handleChange}
                  id='billing_contact'
                />
                {formik.touched.billing_contact && formik.errors.billing_contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.billing_contact}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                <span className='required'>Support Contact</span>
              </label>

              <div className='col-lg-12 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='123456789'
                  value={formik.values.support_contact}
                  onChange={formik.handleChange}
                  id='support_contact'
                />
                {formik.touched.support_contact && formik.errors.support_contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.support_contact}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex justify-content-end mt-5 gap-5'>
              <button
                type='button'
                className='btn btn-light fw-bold text-dark'
                data-bs-dismiss='modal'
              >
                Close
              </button>

              <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                Update user
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalProfileForm
