import React, {useEffect, useState} from 'react'
import ResellersHeadingDetail from './ResellersHeadingDetail'
import {KTSVG} from '../../../_metronic/helpers'
import useCallApi from '../../../apis/api'
import {ResellersStat} from '../../../types/reseller'
import {getHumanValueStorage} from '../../../utility/utils'
import Select from 'react-select'
import ExportClientAsCSV from '../../components/clients/export-client'

export default function ResellersDashboard() {
  const [clients, setClients] = useState<ResellersStat[]>([])
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortedColumn, setSortedColumn] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId
  const {getData} = useCallApi()

  const handleSort = (column: string) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortedColumn(column)
    }
  }

  useEffect(() => {
    if (resellerUid) {
      getData(`usage_stats/resellers_stats/` + resellerUid).then((data) => {
        let sortedClients = [...data.clients]

        if (sortedColumn === 'name') {
          sortedClients.sort((a, b) => a.name.localeCompare(b.name))
        } else if (sortedColumn === 'storageUsage') {
          sortedClients.sort((a, b) => a.storage_usage - b.storage_usage)
        }

        if (sortOrder === 'desc') {
          sortedClients.reverse()
        }
        setClients(sortedClients)
      })
    }
  }, [resellerUid, sortedColumn, sortOrder, getData])

  const handleItemsPerPageChange = (selectedOption: any) => {
    setItemsPerPage(selectedOption.value)
    setCurrentPage(1)
  }

  const indexOfLastClient = currentPage * itemsPerPage
  const indexOfFirstClient = indexOfLastClient - itemsPerPage
  const currentClients = clients.slice(indexOfFirstClient, indexOfLastClient)

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    setCurrentPage(1)
  }

  const filteredClients = currentClients.filter((currentClients) =>
    currentClients.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <>
      <ResellersHeadingDetail />
      <div className='d-flex justify-content-between my-5'>
        <div className='d-flex align-items-center position-relative me-4 '>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-3 position-absolute ms-3 text-primary '
          />
          <input
            type='text'
            id='kt_filter_search'
            className='form-control form-control-white form-control-sm w-lg-350px w-150px ps-9'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className='d-flex gap-3'>
          <ExportClientAsCSV clients={clients} />
        </div>
      </div>
      <div className={`table-responsive  card`}>
        <table
          className={`table table-row-bordered  table-row-gray-300 align-middle gs-0 gy-3 p-5`}
        >
          <thead>
            <tr className=' text-gray-700 text-capitalize dashboardTableHead'>
              <th className='min-w-70px px-5' >
                name {sortedColumn === 'name'}{' '}
                <i className='bi bi-arrow-down-up ps-2' onClick={() => handleSort('name')} style={{cursor: 'pointer'}}></i>
              </th>
              <th className='min-w-70px' >
                storage usage {sortedColumn === 'storageUsage'}
                <i className='bi bi-arrow-down-up ps-2' onClick={() => handleSort('storageUsage')} style={{cursor: 'pointer'}}></i>
              </th>
              <th className='min-w-70px'>managed workload</th>
              <th className='min-w-70px'>BR enterprise</th>
              <th className='min-w-70px'>BR enterprise plus</th>
              <th className='min-w-70px'>veeam server agent</th>
              <th className='min-w-70px'>veeam workstation agent</th>
              <th className='min-w-70px'>veeam cloud connect VM</th>
              <th className='min-w-70px'>veeam cloud connect workstation</th>
              <th className='min-w-70px'>veeam cloud connect server</th>
              <th className='min-w-70px'>veeam backup for m365</th>
              <th className='min-w-70px'>veeam backup</th>
              <th className='min-w-70px '>Replication Standard License Usage</th>
            </tr>
          </thead>

          <tbody className='px-5'>
            {filteredClients.slice(0, itemsPerPage).map((client, id) => (
              <tr className=' text-gray-700 text-capitalize dashboardTable '>
                <td className='min-w-150px fw-bold text-black px-5'>
                  <h6>{client.name}</h6>
                </td>
                <td className='min-w-150px'>{getHumanValueStorage(client?.storage_usage ?? 0)}</td>
                <td>
                  {' '}
                  {client.is_managed
                    ? client.br_enterprise +
                      client.br_enterprise_new +
                      client.br_enterprise_plus +
                      client.br_enterprise_plus_new +
                      client.veeam_server_agent +
                      client.veeam_server_agent_new
                    : 0}{' '}
                </td>
                <td>{client.br_enterprise + client.br_enterprise_new} </td>
                <td>{client.br_enterprise_plus + client.br_enterprise_plus_new} </td>
                <td>{client.veeam_server_agent + client.veeam_server_agent_new} </td>
                <td>{client.veeam_workstation_agent + client.veeam_workstation_agent_new} </td>
                <td>{client.veeam_cloud_connect_vm + client.veeam_cloud_connect_vm_new}</td>
                <td>
                  {client.veeam_cloud_connect_workstation +
                    client.veeam_cloud_connect_workstation_new}{' '}
                </td>
                <td>
                  {client.veeam_cloud_connect_server + client.veeam_cloud_connect_server_new}{' '}
                </td>
                <td>{client.veeam_backup_for_m365 + client.veeam_backup_for_m365_new} </td>
                <td>0</td>
                <td>0</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='d-flex justify-content-between align-items-center pt-10 '>
        <div className='d-flex align-items-center gap-2'>
          Show
          <Select
            className={` `}
            options={[
              {value: 10, label: '10'},
              {value: 20, label: '20'},
              {value: 50, label: '50'},
            ]}
            value={{value: itemsPerPage, label: `${itemsPerPage} `}}
            onChange={handleItemsPerPageChange}
            menuPosition="fixed" 
            menuPlacement="top"   
           isSearchable = {false}
          />
          entries
        </div>
        <div className={` flex-stack  flex-wrap pt-10 ${filteredClients.length > 0 ? "" : "d-none"}`}>
          <ul className='pagination'>
            <li className='page-item previous' style={{cursor:"pointer"}}>
              <div
                className='page-link'
                onClick={() => {
                  if (currentPage > 1) {
                    paginate(currentPage - 1)
                  }
                }}
              >
                <i className='fa-solid fa-angles-left' ></i>
              </div>
            </li>
            {Array.from({length: Math.ceil(clients.length / itemsPerPage)}).map((_, index) => (
              <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                <div className='page-link' onClick={() => paginate(index + 1)}>
                  {index + 1}
                </div>
              </li>
            ))}

            <li className='page-item next' style={{cursor:"pointer"}}>
              <div
                className='page-link'
                onClick={() => {
                  if (currentPage < Math.ceil(clients.length / itemsPerPage)) {
                    paginate(currentPage + 1)
                  }
                }}
              >
                <i className='fa-solid fa-angles-right ' ></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
