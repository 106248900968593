import React, { useState} from 'react'
import Swal from 'sweetalert2'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'



type InvoiceItem = {
  id: string
  created: number
  status: string
  total: number
  account_name: string
}
type UpdatePendingInvoiceProps = {
  invoice: InvoiceItem[] | undefined
  handlePendingInvoice: (resellerId: string) => void
  resellerIds: string
  paymentAmount: () => void

}

export default function UpdatePendingInvoice({
  invoice,
  handlePendingInvoice,
  resellerIds,
  paymentAmount,
}: UpdatePendingInvoiceProps) {
  const {auth} = useAuth()
  const [editableAmount, setEditableAmount] = useState<{[key: string]: boolean}>({})
  const [editedAmount, setEditedAmount] = useState<{[key: string]: string}>({})
  const [isEditing, setIsEditing] = useState<{[key: string]: boolean}>({})
  

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${day}/${month}/${year}`
  }

  const handleEditClick = (id: string) => {
    setEditableAmount((prevEditableAmount) => ({...prevEditableAmount, [id]: true}))
  }

  const handleOkClick = (id: string) => {
    setIsEditing((prevIsEditing) => ({...prevIsEditing, [id]: true}))

    const editedAmountValue = editedAmount[id]
    if (!editedAmountValue || isNaN(parseFloat(editedAmountValue))) {
      return
    }

    fetch(`${BASE_URL}/usage_stats/update_invoice/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: parseFloat(editedAmountValue) * 100,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Unauthorized')
          })
        }

        return response.json()
      })
      .then(() => {
        paymentAmount()
        handlePendingInvoice(resellerIds)
        Swal.fire({
          icon: 'success',
          text: 'Updated successfully!',
          confirmButtonColor: '#009EF7',
        })
      })

      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsEditing((prevIsEditing) => ({...prevIsEditing, [id]: false}))
      })
    setEditableAmount((prevEditableAmount) => ({...prevEditableAmount, [id]: false}))
  }




  return (
    <>
   
    <div>
      <div
        className='modal'
        id='pendingInvoiceAdmin'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Pending Invoices
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body card'>
              <div className='table-responsive '>
                {invoice ? (
                  <table className='table gs-7 gy-7 gx-7 fw-normal'>
                    <thead>
                      <tr className='fw-semibold fs-7 text-gray-800 border-bottom border-gray-200'>
                        <th className='d-flex '>Invoice Id.</th>
                        <th>
                          Date <i style={{cursor: 'pointer'}}></i>
                        </th>
                        <th>Status</th>
                        <th>Amount </th>
                        <th>Edit Amount</th>
                      </tr>
                    </thead>
                    <tbody className='fs-7 align-middle'>
                      {invoice?.flatMap((item, i) => (
                        <tr key={item.id}>
                          <td className=' align-middle'>{item.id.substring(0, 12)}...</td>
                          <td>{formatDate(item.created)}</td>
                          <td>
                            {' '}
                            <span
                              className={`  ${
                                item.status === 'paid'
                                  ? 'bg-light-success text-success'
                                  : item.status === 'open'
                                  ? 'bg-light-warning text-warning'
                                  : 'bg-light-danger text-danger'
                              } p-2 rounded`}
                            >
                              {item.status}
                            </span>
                          </td>

                          <td className='fw-bold'>
                            {editableAmount[item.id] ? (
                              <input
                                type='text'
                                className='form-control w-70px h-30px'
                                defaultValue={(editedAmount[item.id] !== undefined
                                  ? parseFloat(editedAmount[item.id]).toFixed(2)
                                  : (item.total * 0.01).toFixed(2)
                                ).toString()}
                                onChange={(e) => {
                                  const value = e.target.value
                                  setEditedAmount((prevEditedAmount) => ({
                                    ...prevEditedAmount,
                                    [item.id]: value,
                                  }))
                                }}
                              />
                            ) : (
                              `$${Math.round(item.total * 0.01 * 100) / 100}`
                            )}
                          </td>

                          <td>
                            {editableAmount[item.id] ? (
                              <button
                                type='button'
                                className='btn btn-primary btn-sm py-1 px-4'
                                onClick={() => handleOkClick(item.id)}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                type='button'
                                className='btn btn-primary btn-sm py-1 px-4'
                                onClick={() => handleEditClick(item.id)}

                              >
                                {isEditing[item.id] ? 'Loading..' : 'Edit'}
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='d-flex justify-content-center align-items-center py-5'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
       </>
  )
}
