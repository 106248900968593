import {Link} from 'react-router-dom'
import {requestPassword} from '../core/_requests'
import Swal from 'sweetalert2'

export function CheckYourMail() {


  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Email sent successfully!',
      confirmButtonColor: '#009EF7',
    })
  }
  const email = localStorage.getItem('email')
  
  const handleResend = async () => {
    try {
      if (email !== null) {
        const response = await requestPassword(email)
        if (response.status === 200) {
          handleAlert()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  

  return (
    <div className='container '>
      <div className='row d-flex align-items-center  ' style={{height: '70vh'}}>
        <div className='col ms-5'>
          <h1 className=' fw-bolder mb-3 text-primary'>
            {' '}
            <Link to={'/auth/forgot-password'}>
              <i className='bi bi-arrow-left mt-5 fs-1 me-3 '></i>
            </Link>
            Please check your email
          </h1>
          <p className='pt-5'>
            The reset password link has been sent to email <br /> <b>{email}</b>. You might check in
            the spam email as well.{' '}
          </p>

          <p className='pt-5'>
            Haven't received the email?{' '}
            <span
              onClick={handleResend}
              className='fw-bold text-primary '
              style={{cursor: 'pointer'}}
            >
              Resend
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
