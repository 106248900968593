import {create} from 'zustand'

interface ILoading {
  loading: boolean;
  setLoading: (loading:boolean ) => void
}

export const useLoading = create<ILoading>()((set) => ({
  loading: false,
  setLoading: (loading:boolean)=>set({loading})
}))
