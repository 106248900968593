import {create} from 'zustand'
import {Client} from '../types/client'
import {Reseller, ResellersStat} from '../types/reseller'

interface IStore {
  resellersStats: ResellersStat[]
  setResellersStat: (ResellersStats: ResellersStat[]) => void
}

export const useStats = create<IStore>()((set) => ({
  resellersStats: [],
  setResellersStat: (resellersStats) => set((state) => ({resellersStats: resellersStats})),
}))
