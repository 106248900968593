// @ts-nocheck
import {ChangeEvent} from 'react'
import {monthsMap} from './dates'

const now = new Date()

const month = now.getMonth() + 1

const year = now.getFullYear()

// get previous 12 months
const monthsList = Array.from({length: 12}, (v, i) => {
  const m = month - i
  return {
    month: m < 1 ? 12 + m : m,
    year: m < 1 ? year - 1 : year,
  }
})

export default function LicensingFilter({
  onChanged,
  defaultValue,
  value,
}: {
  onChanged: (date: string) => void
  defaultValue: string
  value: string
}) {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChanged(e.target.value)
  }

  return (
    <div className='m-2'>
      <select
        onChange={handleChange}
        className='form-select form-select-solid'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        defaultValue={defaultValue}
        value={value}
      >
        {monthsList.map((v, i) => {
          return (
            <option
              key={i}
              selected={
                v.month < 10
                  ? `0${v.month}-${v.year}` === defaultValue
                  : `${v.month}-${v.year}` === defaultValue
              }
              value={v.month < 10 ? `0${v.month}-${v.year}` : `${v.month}-${v.year}`}
            >
              {monthsMap[v.month < 10 ? `0${v.month}` : `${v.month}`] + ' ' + v.year}
            </option>
          )
        })}
      </select>
    </div>
  )
}
