import React, {useRef, useState} from 'react'
import Swal from 'sweetalert2'
import {BASE_URL} from '../../../utility/constants'
import {useAuth} from '../../modules/auth'
import ModalAddCostPerUnitTable from './ModalAddCostPerUnitTable'

interface CostValues {
  br_enterprise: number
  br_enterprise_plus: number
  storage_usage: number
  veeam_backup_for_m365: number
  veeam_cloud_connect_server: number
  veeam_cloud_connect_vm: number
  veeam_cloud_connect_workstation: number
  veeam_server_agent: number
  veeam_workstation_agent: number
}

interface ModalAddCostPerUnitProps {
  getcostToChange: () => void
}

const ModalAddCostPerUnit: React.FC<ModalAddCostPerUnitProps> = ({getcostToChange}) => {
  const {auth} = useAuth()
  const [data, setData] = useState<CostValues>()
  const [isCustomizeSelected, setIsCustomizeSelected] = useState<boolean>(true)
  const [selectedResellerId, setSelectedResellerId] = useState<string | null>()
  const [customData, setCustomData] = useState<CostValues>({
    br_enterprise: 0,
    br_enterprise_plus: 0,
    storage_usage: 0,
    veeam_backup_for_m365: 0,
    veeam_cloud_connect_server: 0,
    veeam_cloud_connect_vm: 0,
    veeam_cloud_connect_workstation: 0,
    veeam_server_agent: 0,
    veeam_workstation_agent: 0,
  })

  const [dispatchReset, setDispatchReset] = useState<boolean>(false)

  const formRef = useRef<HTMLFormElement | null>(null)

  const getDefaultCost = () => {
    fetch(`${BASE_URL}/usage_stats/get_default_costs`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((fetchedData) => {
        console.log('fetchedData', fetchedData.data[0])
        const values = fetchedData.data[0]
        setData(values)
        console.log(values, 'values')
        console.log(data, 'data')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Added successfully!',
      confirmButtonColor: '#009EF7',
    })
  }

  const handleSubmit = () => {
    if (!selectedResellerId) {
      Swal.fire({
        icon: 'error',
        text: 'Please select a reseller',
        confirmButtonColor: '#009EF7',
      })
      return
    }
    const currentData = isCustomizeSelected
      ? {...customData}
      : {
          storage_usage: data?.storage_usage,
          br_enterprise: data?.br_enterprise,
          br_enterprise_plus: data?.br_enterprise_plus,
          veeam_backup_for_m365: data?.veeam_backup_for_m365,
          veeam_cloud_connect_server: data?.veeam_cloud_connect_server,
          veeam_cloud_connect_vm: data?.veeam_cloud_connect_vm,
          veeam_cloud_connect_workstation: data?.veeam_cloud_connect_workstation,
          veeam_server_agent: data?.veeam_server_agent,
          veeam_workstation_agent: data?.veeam_workstation_agent,
        }

    fetch(`${BASE_URL}/usage_stats/reseller_cost/${selectedResellerId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
      body: JSON.stringify(currentData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else if (response.status === 400) {
          return response.json().then((errorData) => {
            const errorMessage = errorData.error
            Swal.fire({
              icon: 'error',
              text: errorMessage,
              confirmButtonColor: '#009EF7',
            })
            throw new Error(errorData.message || 'Bad Request')
          })
        } else {
          throw new Error('Failed to update')
        }
      })
      .then(() => {
        getcostToChange()
        handleAlert()
        formRef.current?.reset()
      })
      .catch((error) => {
        console.error('API Request Error:', error)
      })
      .finally(() => {
        setSelectedResellerId(null)
        formRef.current?.reset()
        setDispatchReset(true)
        setCustomData({
          br_enterprise: 0,
          br_enterprise_plus: 0,
          storage_usage: 0,
          veeam_backup_for_m365: 0,
          veeam_cloud_connect_server: 0,
          veeam_cloud_connect_vm: 0,
          veeam_cloud_connect_workstation: 0,
          veeam_server_agent: 0,
          veeam_workstation_agent: 0,
        })
      })
  }

  const handleClose = () => {
    formRef.current?.reset()
    setDispatchReset(true)
  }

  return (
    <div>
      <div
        className='modal fade'
        id='modalAddCost'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Cost Per Unit
              </h5>

              <div className='d-flex gap-3 me-3'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault1'
                    checked={isCustomizeSelected}
                    onChange={() => setIsCustomizeSelected(true)}
                  />
                  <label htmlFor='flexRadioDefault1'>Customize</label>
                </div>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    onClick={() => getDefaultCost()}
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault2'
                    checked={!isCustomizeSelected}
                    onChange={() => setIsCustomizeSelected(false)}
                  />
                  <label htmlFor='flexRadioDefault2'>Use Default</label>
                </div>
              </div>
            </div>
            <ModalAddCostPerUnitTable
              formRef={formRef}
              dispatchReset={dispatchReset}
              setDispatchReset={setDispatchReset}
              setSelectedResellerId={setSelectedResellerId}
              isCustomizeSelected={isCustomizeSelected}
              data={data}
              setCustomData={setCustomData}
            />
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={handleSubmit}
                data-bs-dismiss='modal'
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ModalAddCostPerUnit}
