import {useCallback, useState} from 'react'
import {useAuth} from '../app/modules/auth'
import {dynamicSort} from '../utility/utils'
import {BASE_URL} from '../utility/constants'

/*** Get Licensing Report List API ******/

export default function useLicensesReport() {
  const [licensingReport, setLicensingReport] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const {auth} = useAuth()

  const getSummary = useCallback(
    async (month: string, year: string) => {
      setLoading(true)
      setLicensingReport([])
      try {
        const res = await fetch(
          `${BASE_URL}/clients_license_usage/summary/?month=${month}-${year}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.api_token}`,
            },
          }
        )
        const data = await res.json()
        const report = Object.keys(data).map((k) => ({
          ...data[k],
          name: data[k].reseller_name,
        }))

        setLicensingReport(report.sort(dynamicSort('name')))
        setLoading(false)
        return report
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [auth?.api_token]
  )

  return {getSummary, licensingReport, loading}
}
