/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, ChangeEvent, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Button, Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {
  defaultCreateAppData,
  ICreateAppData,
} from '../../../../../_metronic/partials/modals/create-app-stepper/IAppModels'
import {useThemeMode} from '../../../../../_metronic/partials'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import useCallApi from '../../../../../apis/api'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SendEmail = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [hasError, setHasError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const {mode} = useThemeMode()
  const {getData, loading} = useCallApi()

  const {resellerUid, month} = useParams()

  const resetEmailAndSuccess = () => {
    setEmail('')
    setSuccess(false)
    setIsEmailValid(true)
  }

  useEffect(() => {
    if (!show) {
      resetEmailAndSuccess()
    }
  }, [show])

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setIsEmailValid(validateEmail(newEmail))
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.appName || !data.appBasic.appType) {
      return false
    }
    return true
  }

  const checkAppDataBase = (): boolean => {
    if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
      return false
    }

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkAppDataBase()) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }

  const submit = () => {
    window.location.reload()
  }

  const handleSubmit = () => {
    getData(
      `usage_stats/send_client_email?reseller=${resellerUid}&month=${month}&email=${email}`
    ).then((data) => {
      setSuccess(true)
      setEmail('')
    })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered  mw-450px`}
      style={{
        marginLeft: mode === 'light' ? '5%' : 'auto',
      }}
      show={show}
      onHide={() => {
        handleClose()
      }}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Send CSV to Email</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='fv-row my-10 ms-8 w-75'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Email</span>
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='appname'
          placeholder=''
          value={email}
          onChange={handleEmailChange}
        />
        {!data.appBasic.appName && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              App name is required
            </div>
          </div>
        )}
        <Button className='mt-10' onClick={handleSubmit}>
          {success ? 'Email Sent' : 'Send'}
        </Button>

        {!isEmailValid && (
          <div className='fv-plugins-message-container'>
            <div data-validator='email' className='fv-help-block'>
              Invalid email format
            </div>
          </div>
        )}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {SendEmail}
