/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {IUserAccount} from '../../../../types/responseTypes/userAccount.type'
import {BASE_URL} from '../../../../utility/constants'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import ModalAddNewUser from './ModalAddNewUser'
import {UserAccountActionModal} from './UserAccountActionModal'
import UserAccountPagination from './UserAccountPagination'

type Props = {
  className: string
}

const UserAccount: React.FC<Props> = ({className}) => {
  const {auth} = useAuth()
  const userJSON = localStorage.getItem('user')
  const user = userJSON ? JSON.parse(userJSON) : null
  const resellerUid = user.resellerId
  const [data, setData] = useState<IUserAccount[]>([])
  const [sortOrderUser, setSortOrderUser] = useState<'asc' | 'desc'>('asc')
  const [sortOrderRole, setSortOrderRole] = useState<'asc' | 'desc'>('asc')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const [selectedUser, setSelectedUser] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  const getResellerUsersData = () => {
    fetch(`${BASE_URL}/usage_stats/reseller_users/${resellerUid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((fetchedData) => {
        setData(fetchedData.reseller)
        console.log('fetchedData.reseller', fetchedData.reseller)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getResellerUsersData()
  }, [])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  const filteredData = data.filter((item) =>
    item.username.toLowerCase().includes(searchInput.toLowerCase())
  )

  const clearSearchInput = () => {
    setSearchInput('')
  }

  const paginateData = (data: IUserAccount[], currentPage: number, itemsPerPage: number) => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return data.slice(startIndex, endIndex)
  }

  const totalPages = Math.ceil(data.length / itemsPerPage)

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page)
    }
  }

  const handleUserHeaderClick = () => {
    const newSortOrder = sortOrderUser === 'asc' ? 'desc' : 'asc'
    setSortOrderUser(newSortOrder)

    const sortedData = data.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a.username.localeCompare(b.username)
      } else {
        return b.username.localeCompare(a.username)
      }
    })

    setData(sortedData)
  }

  const handleRoleHeaderClick = () => {
    const newSortOrder = sortOrderRole === 'asc' ? 'desc' : 'asc'
    setSortOrderRole(newSortOrder)

    const sortedData = data.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a.type.localeCompare(b.type)
      } else {
        return b.type.localeCompare(a.type)
      }
    })

    setData(sortedData)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectAll(!selectAll)
      setSelectedUser(data.map((item) => item.id))
    } else {
      setSelectAll(false)
      setSelectedUser([])
    }
  }
  const handleSelectUser = (userId: string) => {
    const updatedSelection = selectedUser.includes(userId)
      ? selectedUser.filter((id) => id !== userId)
      : [...selectedUser, userId]

    setSelectAll(updatedSelection.length === data.length)
    setSelectedUser(updatedSelection)
  }
  return (
    <div className='card '>
      <div className='card-header '>
        <h3 className='card-title'>User Accounts</h3>
        <div className='d-flex justify-content-end  gap-5'>
          <div className='mt-5'>
            <div className='d-flex align-items-center position-relative me-4 '>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-3 position-absolute ms-3 text-primary '
              />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9 py-3 '
                placeholder='Search User'
                value={searchInput}
                onChange={handleSearchInputChange}
                onClick={clearSearchInput}
              />
            </div>
          </div>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-primary py-4 mt-2'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_profile_2'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Add new user
            </a>
          </div>
        </div>
        <ModalAddNewUser handleClose={() => getResellerUsersData()} />
      </div>
      <div className='card-body'>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_7' role='tabpanel'>
            <div className='table-responsive'>
              <table id='resellerDataTable' className='table gs-7 gy-7 gx-7'>
                <thead>
                  <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200 '>
                    <th className='d-flex gap-5 '>
                      {' '}
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{cursor: 'pointer'}}
                        />
                      </div>
                      <div className='ms-2'>
                        User{' '}
                        <i
                          className={`bi bi-arrow-down-up ${
                            sortOrderUser === 'asc' ? 'up' : 'down'
                          }`}
                          onClick={handleUserHeaderClick}
                          style={{cursor: 'pointer'}}
                        ></i>
                      </div>
                    </th>
                    <th>
                      Role{' '}
                      <i
                        className={`bi bi-arrow-down-up ${sortOrderUser === 'asc' ? 'up' : 'down'}`}
                        onClick={handleRoleHeaderClick}
                        style={{cursor: 'pointer'}}
                      ></i>{' '}
                    </th>
                    <th>Last Login</th>
                    <th>Two-Step</th>
                    <th>Joined Date </th>
                    <th className='text-end'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paginateData(filteredData, currentPage, itemsPerPage).map((item, i) => (
                    <tr className='align-middle'>
                      <td className='d-flex gap-5'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid '>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            checked={selectedUser.includes(item.id)}
                            onChange={() => handleSelectUser(item.id)}
                            style={{cursor: 'pointer'}}
                          />
                        </div>

                        <div className='d-flex  '>
                          <div className='symbol symbol-45px rounded-circle me-5'>
                            <img
                              src={
                                item.profile_image
                                  ? `${BASE_URL}/static/profile_images/${item.profile_image}`
                                  : toAbsoluteUrl('/media/avatars/blank.png')
                              }
                              alt=''
                              className='rounded-circle'
                            />
                          </div>
                          <div>
                            <b>{item.username}</b>
                            <br />
                            {item.email}
                          </div>
                        </div>
                      </td>
                      <td>{item.type}</td>
                      {/* //for nor showing joined date  */}

                      <td>{moment(item.date_joined).format('hh:mm A , DD/MM/YYYY ')}</td>
                      <td>
                        <span
                          className={` fw-bold p-2 ${
                            item.two_factor_auth
                              ? 'bg-light-success text-success'
                              : 'bg-light-danger text-danger'
                          }`}
                        >
                          {item.two_factor_auth ? 'Enable' : 'Disable'}
                        </span>
                      </td>

                      <td>{moment(item.date_joined).format('hh:mm A , DD/MM/YYYY ')}</td>

                      <td className='text-end align-middle me-5'>
                        <div className='dropdown'>
                          <div
                            className='btn  '
                            id='dropdownMenuButton1'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            {' '}
                            <KTSVG
                              path='/media/icons/duotune/general/gen053.svg'
                              className='svg-icon-muted svg-icon-hx'
                            />
                          </div>

                          <UserAccountActionModal
                            id={item.id}
                            handleClose={() => getResellerUsersData()}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <UserAccountPagination
                filteredData={filteredData}
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UserAccount}
