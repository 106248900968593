import { create } from 'zustand';

type InvoiceItem = {
  id: string;
  created: number;
  status: string;
  amount_due: number;
  account_name: string;
};

type InvoiceStore = {
  invoicesOfStore: InvoiceItem[] | undefined;
  setInvoicesOfStore: (invoicesOfStore: InvoiceItem[] | undefined) => void;
  updateInvoices: () => void;
};

export const useInvoiceStore = create<InvoiceStore>((set) => ({
  invoicesOfStore: undefined,
  setInvoicesOfStore: (invoicesOfStore) => set({ invoicesOfStore }),
  updateInvoices: () => {
    console.log("updateInvoices")
    set((state) => {
      const updatedInvoices = state.invoicesOfStore || [];
      return { ...state, invoicesOfStore: updatedInvoices };
    });
  },
}));