/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useEffect, useRef, useState} from 'react'
import {monthsMap} from '../filter/dates'
import useCallApi from '../../../apis/api'

type Props = {
  className: string
}

function capitalizeAll(s) {
  return s
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ')
}

const AllLicensesMonth: React.FC<Props> = ({className}) => {
  const [graphData, setGraphData] = useState({})
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [plotableData, setPlotableData] = useState([])
  const {getData, loading} = useCallApi()

  function refreshData(month) {
    if (!graphData[month]) return
    const data = []
    Object.keys(graphData[month]).forEach((key) => {
      if (key !== 'month') {
        data.push({x: capitalizeAll(key.replaceAll('_', ' ')), y: graphData[month][key]})
      }
    })
    setPlotableData(data)
  }

  useEffect(() => {
    if (graphData) refreshData(Object.keys(graphData)[0])
  }, [graphData])

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, plotableData))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, plotableData])

  ////////*****  Get Graph Data *****////////

  useEffect(() => {
    getData('clients_license_usage/year_stats/').then((data) => {
      setGraphData(data)
    })
  }, [])

  const now = new Date()

  const month = now.getMonth() + 1

  const year = now.getFullYear()

  const monthsList = Array.from({length: 12}, (v, i) => {
    const m = month - i
    return {
      month: m < 1 ? 12 + m : m,
      year: m < 1 ? year - 1 : year,
    }
  })

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start'>
          <span className='card-label fw-bold fs-3 mb-1'>Monthly Total Licenses</span>
          <div className='m-2 w-40'>
            <select
              onChange={(v) => {
                refreshData(v.target.value)
              }}
              className='form-select form-select-solid '
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'1'}
            >
              {monthsList.map((v, i) => {
                return (
                  <option
                    key={i}
                    // selected={
                    //   v.month < 10
                    //     ? `0${v.month}-${v.year}` === defaultValue
                    //     : `${v.month}-${v.year}` === defaultValue
                    // }
                    value={v.month < 10 ? `0${v.month}-${v.year}` : `${v.month}-${v.year}`}
                  >
                    {monthsMap[v.month < 10 ? `0${v.month}` : `${v.month}`] + ' ' + v.year}
                  </option>
                )
              })}
            </select>
          </div>
          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AllLicensesMonth}

function getChartOptions(height: number, plotableData): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'license',
        data: plotableData,
      },
      // {
      //   name: 'license',
      //   data: [76, 85, 101, 98, 87, 105],
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'category',
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
