import { useState } from 'react'
import StripeAddNewCard from './StripeAddNewCard'

export default function ModalPaymentMethod({handleGetPaymentDetail}:{handleGetPaymentDetail:()=>void}) {
  
  
  return (
    <>
      <div className='modal fade ' id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>Add New Card</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className=' p-10'>
              <StripeAddNewCard handleGetPaymentDetail={handleGetPaymentDetail}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


