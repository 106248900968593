import {KTSVG} from '../../../../_metronic/helpers'

export default function DetailsInvoice({
  id,
  onClose,
  details,
}: {
  id: string | number
  onClose: () => void
  details: any
}) {

    const formatDate = (timestamp: number): string => {
        const date = new Date(timestamp * 1000); 
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
      };


  return (
    <div>
      <div className='py-8 px-10 card '>
        <div className='modal-dialog'>
          <div className='modal-content '>
            <div className='modal-header pb-10'>
              <h3 className='modal-title '>Details</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={onClose}
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>
            <table className='table gs-7 gy-7 gx-7'>
              <tbody>
                <tr>
                  <b>Invoice No :</b> {details.id}
                </tr>
                <tr>
                  <b>Date :</b> {formatDate(details.created)}
                </tr>
                <tr>
                  <b>Status:</b> {details.status}
                </tr>
                <tr>
                  <b>Amount :</b>
                  ${details.total * 0.01}
                </tr>
              </tbody>
            </table>
            <div className='modal-footer  '>
              <button type='button' className='btn btn-primary me-2' onClick={onClose}>
                Close
              </button>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
