import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {ResellerRoutes} from './ResellerRoutes'
import { UserRoutes } from './UserRoutes'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {auth, setCurrentUser, currentUser} = useAuth()

  useEffect(() => {
    let userData
    try {
      const user = localStorage.getItem('user')
      userData = JSON.parse(user as string)
    } catch (e) {
      console.log(e)
    }
    if (userData) {
      setCurrentUser(userData)
    }
  }, [])

  return (
    <>
      {/* {currentUser?.groups?.toString() === 'reseller' ? ( */}
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {auth ? (
              <>
                {currentUser?.groups === 'reseller' ? (
                  <Route path='*' element={<ResellerRoutes />} />
                ) 
                : currentUser?.groups === 'reseller_user' ?
                <Route path='*' element={<UserRoutes />} />
                
                :(
                  <Route path='*' element={<PrivateRoutes />} />
                )}
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
      {/* ) : (
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              {auth ? (
                <>
                  {/* <Route index element={<Navigate to='/resellers' />} /> */}
      {/* <Route path='*' element={<PrivateRoutes />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      )} */}
    </>
  )
}

export {AppRoutes}
