import React, {useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {Reseller, ResellersStat} from '../../../../types/reseller'
import {useStats} from '../../../../stores/use-stats'

export default function MonthlySearch({date}: {date: string}) {
  const [resellersList, setResellersList] = useState<any | []>()
  const [value, setValue] = useState<string>('')
  const [dropdown, setDropdown] = useState<ResellersStat[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [mouseOver, setMouseOver] = useState<boolean>(false)
  const navigate = useNavigate()
  const resellersStats = useStats((state) => state.resellersStats)

  /*** Set Resellers List ******/
  useEffect(() => {
    if (resellersStats) {
      setResellersList(resellersStats)
      setDropdown(resellersStats)
    }
  }, [])

  /****** Handle search Dropdown *******/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValue(e.target.value)
    setDropdown(
      resellersList?.filter((reseller: Reseller) =>
        reseller.name.toLowerCase().includes(e.target.value?.toLowerCase())
      )
    )
  }

  return (
    <div className='m-2'>
      <input
        type='search'
        className='form-control'
        aria-label='Default select example'
        placeholder='Select Reseller...'
        value={value}
        onFocus={() => setShow(true)}
        onBlur={() => !mouseOver && setShow(false)}
        onChange={(e) => {
          handleSearchChange(e)
        }}
        style={{
          width: '16rem',
        }}
      />

      <div className='overflow-hidden'>
        <Dropdown.Menu
          style={{
            maxHeight: 300,
            width: '16rem',
            overflowY: 'auto',
            //width: '90%',
            marginTop: '10px',
          }}
          onMouseEnter={() => {
            setMouseOver(true)
          }}
          onMouseLeave={() => {
            setMouseOver(false)
          }}
          show={show}
        >
          {dropdown?.map((item: ResellersStat) => (
            <Dropdown.Item
              onClick={() => {
                setShow(false)
                setValue(item.name)
                navigate(`/individual-monthly-licensing/${date}/${item.id}`)
              }}
              key={item.id}
              onTouchEnd={() => {
                setShow(false)
                setValue(item.name)
                navigate(`/individual-monthly-licensing/${date}/${item.id}`)
              }}
            >
              {item.name.slice(0, 20)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </div>
    </div>
  )
}
