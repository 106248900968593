import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { toAbsoluteUrl} from '../../../../_metronic/helpers'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function CreateAccount() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
      <div className='container'>
<div className='row d-flex align-items-center  ' style={{height:"70vh"}}>

     <div  className='col ms-5'>


    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className=' mb-10'>
  

        <h1 className=' fw-bolder mb-3 text-primary'>
          {' '}
          Create Account
        </h1>
        <p>Already have account <span className='fw-bold text-primary'><Link to={'/'}> Sign in</Link></span></p>

        <div className='text-gray-500 fw-semibold fs-6'>
        </div>
      </div>

  
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}
   
      <div className='fv-row mb-8'>
        <label className='form-label  fw-bolder text-gray-900 fs-6'>Business Name*</label>
        <input
          type='text'
          placeholder='Business Name'
          autoComplete='off'
          className={clsx(
            'form-control bg-transparent',
      
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label  fw-bolder text-gray-900 fs-6'>Business Email Address *</label>
        <input
          type='email'
          placeholder='Enter your Business Email'
          autoComplete='off'
          className={clsx(
            'form-control bg-transparent',
 
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
 
      <div className="form-check mb-5">
  <input className="form-check-input rounded-circle" type="checkbox" value="" id="flexCheckDefault"/>
  <label className='text-gray-600' >
   You Agree to the Terms of Service and Privacy Policy
  </label>
</div>

      <div className='d-flex flex-wrap  pb-lg-0 pt-5'>
        <Link to={'/auth/start-trial'}>
        
   
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Create Account</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        </Link>
      </div>
  
    </form>
    </div>
 
    <div className='orHorizontalLine'>
    <p >or</p>
    <div className='d-flex justify-content-center gap-5 '>
    <i className="bi bi-facebook text-primary fs-1" style={{cursor:"pointer"}}></i>
    <img src={toAbsoluteUrl('/media/login/google_img.svg')} className='' alt='' style={{cursor:"pointer"}}/>
    </div>
    </div>
 
    </div>
   
    </div>
  )
}
