// @ts-nocheck
import {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {dynamicSort, getHumanValueStorage} from '../../../../utility/utils'
import {useAuth} from '../../../modules/auth'
import MonthlyClients from './monthly-clients'
import LicensingFilter from '../../../components/filter/licensing-filter'
import MonthlySearch from './monthly-search'
import useCallApi from '../../../../apis/api'
//lss
export default function IndividualResellerReport() {
  const {resellerUid, month} = useParams()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [clients, setClients] = useState<ResellerStat>()
  const {getData} = useCallApi()

  const defaultValue = useMemo(() => {
    let m = Number(month?.split('-')[0])
    let y = Number(month?.split('-')[1])
    if (m === 1) {
      m = 12
      y = y - 1
    } else {
      m = m + 1
    }
    return `${m < 10 ? '0' + m : m}-${y}`
  }, [month])

  /******* get reseller stats usage ******/

  useEffect(() => {
    if (resellerUid) {
      getData(`clients_license_usage/?month=${month}&reseller_id=${resellerUid}`).then((data) => {
        setClients(data?.sort(dynamicSort('name')))
      })
    }
  }, [resellerUid, month, getData, defaultValue])




  return (
    <div>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title w-100 align-items-center flex-row'>
            <span className='card-label fw-bold fs-3 mb-1 flex-grow-1'>
              {clients ? (
                <div>
                  <a href='/licensing-billing-report'> {clients[0]?.reseller_name} </a> 
                  Monthly Billing Report for {month}
                </div>
              ) : (
                'Reseller Statistics'
              )}
            </span>

            <LicensingFilter
              defaultValue={defaultValue}
              onChanged={(date) => {
                const month = Number(date?.split('-')?.[0])
                const year = Number(date?.split('-')?.[1])
                let newDate = ''
                if (month < 11 && month > 1) {
                  newDate = `0${month - 1}-${year}`
                } else if (month === 1) {
                  newDate = `12-${year - 1}`
                } else {
                  newDate = `${month - 1}-${year}`
                }
                navigate(`/individual-monthly-licensing/${newDate}/${resellerUid}`)
              }}
            />
            {currentUser?.groups?.toString() === 'reseller' ? null : <MonthlySearch date={month} />}
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {clients?.length !== 0 ? (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Reseller Name</th>
                    <th className='min-w-150px'>
                      Storage Usage (VM)
                      
                    </th>
                    <th className='min-w-150px'>
                    Performance Tier
                      
                    </th>
                    <th className='min-w-150px'>
                    Capacity Tier
                      
                    </th>
                    <th className='min-w-120px'>Managed Workloads</th>
                    <th className='min-w-120px'>B & R Enterprise</th>
                    <th className='min-w-120px'>
                      B & R Enterprise
                       Plus
                    </th>
                    <th className='min-w-120px'>
                      Veeam Server 
                      Agent
                    </th>
                    <th className='min-w-120px'>
                      Veeam Workstation 
                      Agent
                    </th>
                    <th className='min-w-120px'>
                      Veeam Cloud 
                      Connect (VM)
                    </th>
                    <th className='min-w-120px text-mid'>Veeam Cloud Connect (Workstation)</th>
                    <th className='min-w-120px'>
                      Veeam Cloud 
                      Connect (Server)
                    </th>
                    <th className='min-w-120px text-mid'>Veeam Backup for M365</th>
                    <th className='min-w-120px'>veeam backup</th>
                    <th className='min-w-120px'>Replication Standard License Usage</th>
                  </tr>
                </thead>

                {clients ? (
                  <tbody>
                    <tr>
                      <td>{clients[0]?.reseller_name}</td>

                      <td>
                        {getHumanValueStorage(
                          clients.reduce((sum, stat) => sum + stat.storage_usage, 0) 
                        )}
                      </td>
                      <td>
                        {getHumanValueStorage(
                          clients.reduce((sum, stat) => sum + (stat.performance_tier ?? 0), 0)
                        )}
                      </td>
                      <td>
                        {getHumanValueStorage(
                          clients.reduce((sum, stat) => sum + (stat.capacity_tier ?? 0), 0)
                        )}
                      </td>
                      <td>
                        {clients.reduce(
                          (sum, stat) => sum + (stat.is_managed ? stat.br_enterprise : 0),
                          0
                        ) +
                          clients.reduce(
                            (sum, stat) => sum + (stat.is_managed ? stat.br_enterprise_plus : 0),
                            0
                          ) +
                          clients.reduce(
                            (sum, stat) => sum + (stat.is_managed ? stat.veeam_server_agent : 0),
                            0
                          )}
                      </td>
                      <td>{clients.reduce((sum, stat) => sum + stat.br_enterprise, 0)}</td>
                      <td>{clients.reduce((sum, stat) => sum + stat.br_enterprise_plus, 0)}</td>
                      <td>{clients.reduce((sum, stat) => sum + stat.veeam_server_agent, 0)}</td>
                      <td>
                        {clients.reduce((sum, stat) => sum + stat.veeam_workstation_agent, 0)}
                      </td>
                      <td>{clients.reduce((sum, stat) => sum + stat.veeam_cloud_connect_vm, 0)}</td>
                      <td>
                        {clients.reduce(
                          (sum, stat) => sum + stat.veeam_cloud_connect_workstation,
                          0
                        )}
                      </td>
                      <td>
                        {clients.reduce((sum, stat) => sum + stat.veeam_cloud_connect_server, 0)}
                      </td>
                      <td>{clients.reduce((sum, stat) => sum + stat.veeam_backup_for_m365, 0)}</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                  <tr>
                    <td>loading..</td>
                  </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <div className='pb-5'>No Data found...</div>
            )}
          </div>
        </div>
      </div>
      
      <MonthlyClients clients={clients} />
    </div>
  )
}
