import {KTSVG} from '../../../../_metronic/helpers'

export default function RemoveProfileImage() {
  return (
    <div>
      <div className='modal fade' id='kt_modal_remove_profile_pic'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>Remove profile picture? </h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x-lg fs-2'></i>
              </div>
            </div>

            <div className='modal-body text-center'>
              <KTSVG
                path='/media/icons/duotune/general/gen027.svg'
                className='svg-icon-danger svg-icon-3hx '
              />
              <h3 className='mt-5'>Are you sure to remove it?</h3>
            </div>

            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button type='button' className='btn btn-danger'>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
