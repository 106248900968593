import React, {useEffect, useRef, useState} from 'react'
import CostToChange from './CostToChange'
import {useAuth} from '../../modules/auth'
import {BASE_URL} from '../../../utility/constants'

interface ValuesType {
  br_enterprise: number
  br_enterprise_plus: number
  storage_usage: number
  veeam_backup_for_m365: number
  veeam_cloud_connect_server: number
  veeam_cloud_connect_vm: number
  veeam_cloud_connect_workstation: number
  veeam_server_agent: number
  veeam_workstation_agent: number
}

export default function DefaultCostPerUnit() {
  const tableRef = useRef(null)
  const {auth} = useAuth()
  const [values, setValues] = useState<ValuesType[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [editedValues, setEditedValues] = useState<ValuesType>({
    br_enterprise: 0,
    br_enterprise_plus: 0,
    storage_usage: 0,
    veeam_backup_for_m365: 0,
    veeam_cloud_connect_server: 0,
    veeam_cloud_connect_vm: 0,
    veeam_cloud_connect_workstation: 0,
    veeam_server_agent: 0,
    veeam_workstation_agent: 0,
  })

  const getDefaultCost = () => {
    fetch(`${BASE_URL}/usage_stats/get_default_costs`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Unauthorized')
        return response.json()
      })
      .then((fetchedData) => {
  
        const apiValues = fetchedData.data[0]
        setValues([apiValues])
        setEditedValues(apiValues)
      })

      .catch((error) => {
        console.error(error)
      })
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleSaveClick = () => {
    const updatedValues = {
      br_enterprise: editedValues.br_enterprise,
      br_enterprise_plus: editedValues.br_enterprise_plus,
      storage_usage: editedValues.storage_usage,
      veeam_backup_for_m365: editedValues.veeam_backup_for_m365,
      veeam_cloud_connect_server: editedValues.veeam_cloud_connect_server,
      veeam_cloud_connect_vm: editedValues.veeam_cloud_connect_vm,
      veeam_cloud_connect_workstation: editedValues.veeam_cloud_connect_workstation,
      veeam_server_agent: editedValues.veeam_server_agent,
      veeam_workstation_agent: editedValues.veeam_workstation_agent,
    }

    setValues([editedValues])
    setIsEditing(false)

    fetch(`${BASE_URL}/usage_stats/update_default_costs`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
      body: JSON.stringify(updatedValues),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error)
          })
        }
        getDefaultCost()
        return response.json()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleInputChange = (key: keyof ValuesType, value: number) => {
    setEditedValues((prev) => ({...prev, [key]: value}))
  }


  useEffect(() => {
    getDefaultCost()
  }, [])

  return (
    <>
      <div className='card table-responsive mb-10'>
        <div className='card-header border-0 pt-5 d-flex'>
          <h3 className='card-title align-items-start flex-row '>
            <span className='card-label fw-bold fs-3 mb-1 pt-1'>Default cost per unit</span>
          </h3>

          <div>
            {isEditing ? (
              <button className='btn btn-success btn-sm' onClick={handleSaveClick}>
                Save
              </button>
            ) : (
              <button className='btn btn-primary btn-sm' onClick={handleEditClick}>
                Edit
              </button>
            )}
          </div>
        </div>
        <table
          ref={tableRef}
          id='resellerDataTable'
          className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
        >
          <thead>
            <tr className='fw-bold text-muted text-capitalize'>
              <th className='min-w-50px ps-5'>Storage Usage</th>
              <th className='min-w-50px'>B & R enterprise</th>
              <th className='min-w-50px'>BR enterprise plus</th>
              <th className='min-w-50px'>veeam server agent</th>
              <th className='min-w-50px'>veeam workstation agent</th>
              <th className='min-w-50px'>veeam cloud connect (VM)</th>
              <th className='min-w-50px'>veeam cloud connect (workstation)</th>
              <th className='min-w-50px'>veeam cloud connect (server)</th>
              <th className='min-w-50px'>veeam backup for m365</th>
            </tr>
          </thead>

          <tbody>
            {Object.entries(values?.[0] || {}).map(([key, val]) => (
              <td
                key={key}
                className={
                  key === 'storage_usage'
                    ? 'ps-5'
                    : '' || key === 'veeam_backup_for_m365'
                    ? 'pe-5'
                    : ''
                }
              >
                {isEditing ? (
                  <input
                    type='number'
                   
                    className='form-control form-control-sm'
                    value={editedValues[key as keyof ValuesType] || ''}
                   
                    onKeyPress={(e) => {
                      if (e.key === '.' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleInputChange(key as keyof ValuesType, parseInt(value, 10));
                      }
                    }}
                  />
                ) : (
                  val
                )}
              </td>
            ))}
          </tbody>
        </table>
      </div>

      <CostToChange />
    </>
  )
}
