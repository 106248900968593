import React from 'react'
import { ForgotPassword } from './ForgotPassword'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export default function ResellerForgotPassword() {
  return (
      <div className='container ' >
    <div className='row d-flex align-items-center  ' style={{height:"70vh"}}>
<div className='col '>
<img src={toAbsoluteUrl('/media/login/image2.png')} alt='img' height={'100%'} width={'100%'}  />

</div>
<div className='col ms-5 '>

<ForgotPassword/>
</div>
</div>
    </div>
  )
}
