import React from 'react'

function UserAccountPagination({
  filteredData,
  handlePageClick,
  currentPage,
  totalPages,
}: {
  filteredData: any
  handlePageClick: (page: number) => void
  currentPage: number
  totalPages: number
}) {
  return (
    <div
      className={`d-flex justify-content-end flex-stack flex-wrap ${
        filteredData.length > 0 ? '' : 'd-none'
      } `}
    >
      <div className=' flex-stack  flex-wrap pt-10'>
        <ul className='pagination'>
          <li className='page-item previous' style={{cursor: 'pointer'}}>
            <div className='page-link' onClick={() => handlePageClick(currentPage - 1)}>
              <i className='fa-solid fa-angles-left'></i>
            </div>
          </li>

          {Array.from({length: totalPages}, (_, index) => (
            <li
              className={index + 1 === currentPage ? 'active' : ''}
              key={index}
              style={{cursor: 'pointer'}}
            >
              <div className='page-link ' onClick={() => handlePageClick(index + 1)}>
                {index + 1}
              </div>
            </li>
          ))}

          <li className='page-item next' style={{cursor: 'pointer'}}>
            <div className='page-link' onClick={() => handlePageClick(currentPage + 1)}>
              <i className='fa-solid fa-angles-right'></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default UserAccountPagination
