/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-is-valid */


import {useAuth} from '../../modules/auth'
import ResellersDashboard from './ResellersDashboard'

function Dashboard() {
  const { currentUser} = useAuth()

  const isReseller = currentUser?.groups === 'reseller'
  const isUserReseller = currentUser?.groups === 'reseller_user'

  if (isReseller || isUserReseller) {
    return <ResellersDashboard />
  }

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <h1> {currentUser?.name} Dashboard </h1>
      </div>
    </>
  )
}

export default Dashboard
