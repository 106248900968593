import React, { useState} from 'react'
import Swal from 'sweetalert2'
import {useInvoiceStore} from '../../../../stores/use-invoice'
import {BASE_URL} from '../../../../utility/constants'
import {useAuth} from '../../auth'

type InvoiceItem = {
  id: string
  created: number
  status: string
  total: number
  account_name: string
}

export default function PendingInvoice({
  invoice,
  handlePendingInvoice,
  getPaymentAmount,
  cardLength,
  amountZero,
}: {
  invoice: InvoiceItem[] | undefined
  handlePendingInvoice: () => void
  getPaymentAmount: () => void
  cardLength: number | undefined
  amountZero: number
}) {
  const {auth} = useAuth()
 

  const [loadingMap, setLoadingMap] = useState<{[key: string]: boolean}>({})
  const {updateInvoices} = useInvoiceStore()

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${day}/${month}/${year}`
  }

  const handlePayClick = (id: string) => {
    setLoadingMap((prevLoadingMap) => ({...prevLoadingMap, [id]: true}))

    fetch(`${BASE_URL}/usage_stats/pay_invoice/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth?.api_token}` ?? '',
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Unauthorized')
          })
        }

        handlePendingInvoice()
        getPaymentAmount()
        updateInvoices()

        Swal.fire({
          icon: 'success',
          text: 'Paid successfully!',
          confirmButtonColor: '#009EF7',
        })

        return response.json()
      })

      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoadingMap((prevLoadingMap) => ({...prevLoadingMap, [id]: false}))
      })
  }

  return (
    <div>
      <div
        className='modal fade'
        id='pendingInvoice'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Pending Invoices
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body card'>
              <div className='table-responsive '>
                <table className='table gs-7 gy-7 gx-7 fw-normal'>
                  <thead>
                    <tr className='fw-semibold fs-7 text-gray-800 border-bottom border-gray-200'>
                      <th className='d-flex '>Invoice Id.</th>
                      <th>
                        Date <i style={{cursor: 'pointer'}}></i>
                      </th>
                      <th>Status</th>
                      <th>Amount </th>
                      <th>Action</th>
                    </tr>
                  </thead>
             
                    <tbody className='fs-7 align-middle'>
                 
                      {invoice?.flatMap((item, i) => (
                        <tr key={item.id}>
                          {/* <td className=' align-middle'>{item.id.substring(0, 12)}...</td> */}
                          <td className=' align-middle'>{item.id}</td>
                          <td>{formatDate(item.created)}</td>
                          <td>
                            {' '}
                            <span
                              className={`  ${
                                item.status === 'paid'
                                  ? 'bg-light-success text-success'
                                  : item.status === 'open'
                                  ? 'bg-light-warning text-warning'
                                  : 'bg-light-danger text-danger'
                              } p-2 rounded`}
                            >
                              {item.status}
                            </span>
                          </td>
                          <td className='fw-bold'>${Math.round(item.total * 0.01 * 100) / 100}</td>

                          <td>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm py-1 px-4'
                              onClick={() => handlePayClick(item.id)}
                              disabled={cardLength === 0 || amountZero <= 0}
                            >
                              {loadingMap[item.id] ? (
                                <div
                                  className='spinner-border '
                                  style={{height: '15px', width: '15px'}}
                                ></div>
                              ) : (
                                'Pay'
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
