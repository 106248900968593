/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {BASE_URL} from '../../../../utility/constants'
import ModalShow from '../../profile/reseller-profile/Modal'
import ModalOtp from './ModalOtp'
import {login} from '../core/_requests'

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [showPassword, setShowpassword] = useState(false)

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, auth} = useAuth()
  const [showModal, setShowModal] = useState(false)

  const onClose = () => {
    setShowModal(false)
  }




  
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)

        fetch(`${BASE_URL}/usage_stats/email_authentication`, {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: formik.values.email,
            password:formik.values.password,
          }),
        })
          .then((response) => {
            // if (!response.ok) throw new Error('Unauthorized')
            if (!response.ok) {
              return response.json().then((data) => {
                throw new Error(data.error); 
              })}
            setLoading(false)
            setShowModal(true)
            return response.json()
          }).then((data) => {
            formik.setStatus(null); 
          })

          .catch((error) => {
            console.error(error.message)
            formik.setStatus(error.message);

            setLoading(false)
          })
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        formik.setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })



  // without OTP

  // const formik = useFormik({
  //   initialValues,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     try {
  //       const {access: jwtToken, refresh: jwtRefreshToken} = await login(
  //         values.email,
  //         values.password
  //       )

  //       const [_,payload,...rest] = jwtToken.split('.')
  //       const decoded = JSON.parse(atob(payload))
  //       setStatus(null)
  //       saveAuth({api_token: jwtToken, refreshToken: jwtRefreshToken})

  //       fetch(`${BASE_URL}/resellers/?name=${decoded.name}`, {
  //         method: 'GET',
  //         headers: {
  //           Authorization: `Bearer ${jwtToken}` ?? '',
  //         },
  //       })
  //         .then((response) => {
  //           if (!response.ok) throw new Error('Unauthorized')
           
  //           return response.json()

  //         })
  //         .then((data) => {
  //           const reseller_id = data[0]?.url?.split('/')[4]
  //           const user = {
  //             id: decoded.user_id,
  //             name: decoded.name,
  //             groups: decoded.groups.toString(),
  //             resellerId: reseller_id,
  //           }

           


  //           localStorage.setItem('user', JSON.stringify(user))
  //           setCurrentUser(user)
  //         })
  //         .catch((error) => {
  //           console.error(error)
  //           saveAuth(undefined)
  //           setStatus('The login details are incorrect')
  //           setSubmitting(false)
  //           setLoading(false)
  //         })
  //     } catch (error) {
  //       console.error(error)
  //       saveAuth(undefined)
  //       setStatus('The login details are incorrect')
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })

  return (
    <>
      <form
        className='form w-100 '
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='  mb-11'>
          <h1 className=' fw-bolder mb-3 text-primary'>Welcome back!</h1>
          <p className='mb-5 pb-5'>
            Don't have an account yet?{' '}
            <span className='fw-bold text-primary' style={{cursor: 'pointer'}}>
              <Link to={'create-account'}> Create account</Link>
            </span>
          </p>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label required fw-bolder text-gray-900 fs-6'>Username</label>

          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx('form-control ')}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-3 position-relative '>
          <label className='form-label required fw-bolder text-gray-900 fs-6'>Password</label>

          <input
            type={showPassword ? 'text' : 'Password'}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control')}
          />
          {showPassword ? (
            <i
              className='bi bi-eye-slash position-absolute '
              style={{fontSize: '26px', right: '20px', top: '35px', cursor: 'pointer'}}
              onClick={() => setShowpassword(false)}
            ></i>
          ) : (
            <i
              className='bi bi-eye position-absolute'
              style={{fontSize: '26px', right: '20px', top: '35px', cursor: 'pointer'}}
              onClick={() => setShowpassword(true)}
            ></i>
          )}

          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <br />

        <Link to={'forgot-password'}>
          <label className='form-label  fw-bolder text-primary fs-6' style={{cursor: 'pointer'}}>
            Forgot Password?
          </label>
        </Link>

        <div className='d-grid mb-10'>
          <button
            id='kt_sign_in_submit'
            className='btn btn-primary mt-5'
            type='submit'
            // type='button'
          >
            {!loading && <span className='indicator-label'>Sign in</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
      </form>
      <ModalShow
        content={
          <ModalOtp
            onClose={onClose}
            userName={formik?.values?.email}
            password={formik?.values?.password}
          />
        }
        onClose={onClose}
        showModal={showModal}
      />
    </>
  )
}
