/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useAuth} from '../../auth'
import ModalShow from './Modal'
import ModalEditNewUser from './ModalEditNewUser'
import ModalRemoveUserAccount from './ModalRemove'

const UserAccountActionModal: FC<{id: string | number; handleClose:()=>void}> = ({id, handleClose}) => {
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalRemove, setShowModalRemove] = useState(false)

  const onCloseEdit = () => {
    handleClose()
    setShowModalEdit(false)
  }
  const onClose2 = () => {
    handleClose()
    setShowModalRemove(false)
  }

  return (
    <>
      <div className='dropdown'>
        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
          <li>
            <div className='dropdown-item'>
              <div onClick={() => setShowModalEdit(true)} className=' px-3 text-dark' style={{cursor:"pointer"}}>
                <i className='bi bi-pencil text-dark me-4'></i> Edit
              </div>
            </div>
          </li>
          <li>
            <div className='dropdown-item'>
              {' '}
              <div onClick={() => setShowModalRemove(true)} className=' px-3 text-danger ' style={{cursor:"pointer"}}>
                <i className='bi bi-trash3 text-danger me-4'></i> Remove
              </div>
            </div>
          </li>
         
        </ul>
      </div>

      <ModalShow
        content={<ModalEditNewUser id={id} onClose={onCloseEdit} />}
        onClose={onCloseEdit}
        showModal={showModalEdit}
      />
      <ModalShow
        content={<ModalRemoveUserAccount id={id} onClose={onClose2} />}
        onClose={onClose2}
        showModal={showModalRemove}
      />
    </>
  )
}

export {UserAccountActionModal}
