/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'

const AuthLayout = () => {

  
  // useEffect(() => {
  //   const root = document.getElementById('root')
  //   if (root) {
  //     root.style.height = '100%'
  //   }
  //   return () => {
  //     if (root) {
  //       root.style.height = 'auto'
  //     }
  //   }
  // }, [])

  const location = useLocation()
  let imageUrl
  if (location.pathname === '/auth') {
    imageUrl = toAbsoluteUrl('/media/login/image.png')
  } else if (location.pathname.startsWith('/auth/')) {
    // You can handle other cases here, e.g., /auth/other
    imageUrl = toAbsoluteUrl('/media/login/image2.png')
  }

  const style = {
    // background: `linear-gradient(45deg, rgba(15,23,42,100) 10%, rgba(26,14,128,100) 45%, rgba(80,0,128,100) 90%)`,
  }
  return (
    <div className='d-lg-flex  flex-lg-row d-flex flex-column justify-content-center align-items-center flex-column-fluid h-100' style={style}>

<div >
        <div className='py-10 d-flex    px-5 px-md-15 w-100'>
          <img src={imageUrl} alt='img' height={'95%'} width={'95%'} />
        </div>
      </div>


      <div className='d-flex  flex-lg-row-fluid w-lg-75   justify-content-center  '>
        <div className='d-flex  px-15 flex-lg-row-fluid'>
          <div className='w-lg-500px '>
            <Outlet />
          </div>
        </div>
      </div>

     
    </div>
  )
}

export {AuthLayout}
